import useSWR from 'swr';
import config from '../../../config'
import { fetcher } from '../../../utils/data/fetcher';


export const useCountries = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.entity : [],
        isLoading: !error && !data,
        isError: error,
    }
}



