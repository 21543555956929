import useSWR from 'swr';
import config from '../../../config'
import { fetcher } from '../../../utils/data/fetcher';




export const useReport = (path, token, filter) => {

    const { data, error } = useSWR(filter ? [`${config.baseUrl}/${path}`, token] : null, fetcher)
    //show error here
    return {
        data: data ? data : {},
        isLoading: !error && !data,
        isError: error
    }
}

