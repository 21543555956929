import React, { useContext } from 'react';
import {
    HStack,
    Heading,
    Avatar,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Portal,
    Button,
    List, ListItem,
    Divider, PopoverArrow, PopoverCloseButton, Box
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { BiChevronDown } from 'react-icons/bi'

import { Context } from '../../context/Context';

function ProfileHeader() {

    const { user, dispatchUser } = useContext(Context)

    const logout = () => {
        dispatchUser({
            type: 'LOG_OUT', user: {}
        });
    }

    return (
        <HStack spacing={2}>
            <Avatar bg="brand.green" name={`${user.first_name} ${user.last_name}`} size="sm" />
            <Heading as="h5" m={0} size="sm">{`${user.first_name} ${user.last_name}`} </Heading>
            <Divider orientation="vertical" />

            <Popover placement="auto-start">
                <PopoverTrigger>
                    <Button variant="link">
                        <Icon as={BiChevronDown} h={6} w={6} />
                    </Button>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <Box p={5}>
                            <List spacing={3}>
                                <ListItem color="brand.primary">
                                    <Link to="/profile/edit">
                                        My Profile
                                    </Link>

                                </ListItem>
                                <ListItem cursor="pointer" onClick={logout}>
                                    Log Out
                                </ListItem>
                            </List>
                        </Box>

                    </PopoverContent>
                </Portal>
            </Popover>
        </HStack>
    )
}

export default ProfileHeader;