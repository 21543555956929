import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import useSWR from 'swr';
import {
    Grid,
    GridItem,
    Text,
    Image,
    Heading,
    SimpleGrid,
    Box,
    TabPanel,
    TabPanels,
    TabList,
    Tab,
    Tabs,
    Center,
    Spinner,
    Flex,
    Stack,
    Tooltip,
    Icon,
    HStack,
    Divider,
} from '@chakra-ui/react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import * as dayjs from 'dayjs';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { WiRain, WiRaindrop } from 'react-icons/wi';
// import VerificationMap from '../../utils/maps/VerificationMap';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import config from '../../config';
import { getPoints } from '../verification/helpers/getPoints';
import { kFormatter } from '../../utils/kFormatter';
import { showToast } from '../../utils/toast';
import { Context } from '../../context/Context';
import { renderVerificationStatus, renderOverallStatus, renderAlternateVegetationCheck, renderOverlay } from '../verification/helpers/renderData';
import GeoTIFFOverlay from './GeoTIFFOverlay';


const fetcher = (url) => {
    return axios.get(url).then(res => res.data)
}

// const dateOffset = (24 * 60 * 60 * 1000) * 1
// const today = new Date();
// today.setTime(today.getTime() - dateOffset);


function FarmAnalysisMapView() {

    const { user } = useContext(Context);

    const isNalda = user.tenant?.tenant_id === "TN-9"


    // console.log(user.tenant)

    const { state } = useLocation()
    const points = getPoints(state.coord)

    const latLng = points[0]

    const { lat, lng } = latLng

    const { data } = useSWR(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lng}&exclude={part}&appid=${config.weatherKey}&units=metric`, fetcher)
    const [loadingSatImage, setLoadingSatImage] = useState(false)
    const [image, setImage] = useState(null)
    const [renderError, setError] = useState("")
    const [satImage, setSatImage] = useState(null)
    const [datesArray, setDatesArray] = useState([])

    // const step = 1000 * 60 * 60 * 24 * 7; // one week

    const max = new Date() // today
    // const max = today;

    const min = useMemo(() => new Date(state.created), [state])//created date

    // const diff = date2.diff(date1)

    // const numberOfTicks = diff / step

    const [selected, setSelected] = useState(max)

    const getSatImage = useCallback(async () => {

        const date = new Date(selected)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        const data = {
            cluster_id: state._id,
            day,
            month,
            year
        }
        setLoadingSatImage(true)

        try {
            setError("")
            const res = await axios.post(`${config.baseUrl}/tie/sat_image`, data)
            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }

            else {
                if (res.data.entity.error) {
                    setError(res.data.entity.error)
                }
                console.log(res.data.entity)

                if (res.data.entity.image_url_tiff) {

                    setSatImage(res.data.entity.image_url_tiff)
                }
                if (res.data.entity.image_url) {
                    setImage(res.data.entity.image_url)
                }

            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
        finally {
            setLoadingSatImage(false)
        }
    }, [selected, setLoadingSatImage, state])


    useEffect(() => {
        getSatImage()
        console.log(selected)
    }, [selected, getSatImage]);


    useEffect(() => {
        const getEarlyWarning = async () => {

            const data = {
                unique_id: state._id
            }
            try {
                const res = await axios.post(`${config.baseUrl}/tie/early-warning`, data)
                console.log({ res })
            }
            catch (e) {
                console.log(e)
                showToast("error", e.message)
            }
        }
        getEarlyWarning();
    }, [state]);


    useEffect(() => {
        var getDaysArray = function () {
            for (var arr = [], dt = min; dt <= new Date(); dt.setDate(dt.getDate() + 7)) {
                arr.push(new Date(dt));
            }
            return arr;
        };
        setDatesArray(getDaysArray())
    }, [min]);


    const calculateCenter = (coord) => {
        const split = coord.split(',')
        const first = split[0]
        const latlng = first.split(':')
        const lat = parseFloat(latlng[0])
        const lng = parseFloat(latlng[1])
        return { lat, lng }
    }


    const renderItem = () => {
        if (loadingSatImage) {
            return (
                <Flex w="100%" h="100%" justify="center">
                    <Center>
                        <Spinner />
                    </Center>
                </Flex>
            )
        }
        if (renderError) {
            return <Flex w="100%" h="100%" justify="center">
                <Center>
                    <h4>{renderError}</h4>
                </Center>
            </Flex>
        }
        return isNalda ?
            <img src={image} alt="satellite" />
            :
            <div style={{ width: '100%', height: '100%' }}>

                {/* <GoogleMap
                    mapTypeId="satellite"
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    center={calculateCenter(state.coord)}
                    zoom={16}
                >

                </GoogleMap> */}
                <GeoTIFFOverlay url={satImage} />
            </div>
    }

    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Farm Analysis</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Heading as="h5" size="sm">Unique ID: {state._id}</Heading>
                    <br />
                    <Box h={"50vh"}>
                        {/* <Tabs variant="soft-rounded" colorScheme="green" onChange={(index) => console.log(index)}>
                            <TabList>
                                <Tab>Provider 1</Tab>
                                <Tab>Provider 2</Tab>
                            </TabList>
                        </Tabs> */}
                        <LoadScript
                            googleMapsApiKey={config.mapsKey}
                        >
                            {renderItem()}
                        </LoadScript>

                    </Box>

                    <div className="map-images" style={{ display: 'flex', overflowX: "scroll", marginTop: 10, width: '100%', padding: '10px 0' }}>
                        {datesArray.map((date, i) => (
                            <Box
                                onClick={() => {
                                    setSelected(date)
                                }}
                                flex="0 0 70px;"
                                key={i}
                                cursor="pointer"
                                mr={4}
                                border="4px"
                                borderColor={dayjs(selected.getTime()).isSame(dayjs(date.getTime())) ? "brand.green" : ""}
                            >
                                <Tooltip label={dayjs(date.getTime()).format('DD/MM/YYYY')} aria-label="A tooltip">
                                    <Image
                                        objectFit="cover"
                                        src="/default-map.png"
                                        alt={date}
                                    />
                                </Tooltip>
                            </Box>

                        ))}
                        <Box
                            onClick={() => {
                                setSelected(max)
                            }}
                            cursor="pointer"
                            mr={4}
                            flex="0 0 70px;"
                            border="4px"
                            borderColor={dayjs(selected.getTime()).format('DD/MM/YYYY') === dayjs(max.getTime()).format('DD/MM/YYYY') ? "brand.green" : ""}
                        >
                            <Tooltip label={dayjs(max.getTime()).format('DD/MM/YYYY')} aria-label="A tooltip">
                                <Image
                                    objectFit="cover"
                                    src="/default-map.png"
                                    alt={max}
                                />
                            </Tooltip>
                        </Box>
                    </div>

                    {/* <Flex justify="center" align="center" mt={8}>
                        <Range
                            values={[selected]}
                            step={step}
                            min={min}
                            max={max}
                            onChange={(values) => setSelected(values[0])}
                            renderMark={({ props, index }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: '16px',
                                        width: '3px',
                                        backgroundColor: "#009688"
                                    }}
                                />
                            )}
                            renderTrack={({ props, children }) => (
                                <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                        ...props.style,
                                        height: '36px',
                                        display: 'flex',
                                        width: '100%',

                                    }}
                                >
                                    <div
                                        ref={props.ref}
                                        style={{
                                            height: '5px',
                                            width: '100%',
                                            borderRadius: '4px',
                                            background: getTrackBackground({
                                                values: [selected],
                                                colors: ['#009688', '#009688'],
                                                min: min,
                                                max: max,

                                            }),
                                            alignSelf: 'center'
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: '25px',
                                        width: '25px',
                                        borderRadius: '50%',
                                        backgroundColor: '#009688',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: '0px 2px 6px #AAA',

                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '-40px',
                                            fontWeight: 'bold',
                                            width: "100px",
                                            fontSize: '14px',
                                            textAlign: "center",
                                            padding: '4px',
                                            borderRadius: '25px',

                                        }}
                                    >
                                        {dayjs(selected).format('MMM D YY')}
                                    </div>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            bottom: '-25px',

                                        }}
                                    >
                                        <Icon as={AiFillCaretUp} w={6} h={6} color="brand.primary" />
                                    </div>
                                </div>
                            )}
                        />
                    </Flex> */}
                    <Box mt={10}>
                        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                            <GridItem colSpan={2}>
                                {/* <Heading as="h5" size="sm" mb={4}>Select Layers</Heading>
                                <br /> */}
                                <SimpleGrid columns={2} spacingY="10px">
                                    <Heading as="h5" size="sm">
                                        Overall Results:
                                    </Heading>
                                    {renderOverallStatus(state.overall_status)}
                                </SimpleGrid>
                                <br />
                                <Heading as="h6" size="xs" mb={4}>Actions</Heading>
                                <SimpleGrid columns={2} spacingY="20px">
                                    <Text fontSize="sm">Vegetation Check:</Text>
                                    <Box>
                                        {renderAlternateVegetationCheck(state.status)}
                                    </Box>
                                    <Text fontSize="sm">Within Country:</Text>
                                    <Box>
                                        {renderVerificationStatus(state.within_country)}
                                    </Box>
                                    <Text fontSize="sm">Within State / Region:</Text>
                                    <Box>
                                        {renderVerificationStatus(state.within_state)}
                                    </Box>
                                    <Text fontSize="sm">Within LGA / District:</Text>
                                    <Box>
                                        {renderVerificationStatus(state.within_lga)}
                                    </Box>
                                    <Text fontSize="sm">Overlay Check:</Text>
                                    <Box>
                                        {renderOverlay(state.overlay)}
                                    </Box>
                                </SimpleGrid>
                                <br />
                                <Tabs>
                                    <TabList>
                                        <Tab _selected={{ color: 'brand.primary', borderColor: 'brand.primary' }}>User Details</Tab>
                                        <Tab _selected={{ color: 'brand.primary', borderColor: 'brand.primary' }}>Returned Info</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <SimpleGrid columns={2} spacingY="10px">
                                                <Text fontSize="sm">State / Region:</Text>
                                                <Heading as="h6" size="xs">{state.state}</Heading>
                                                <Text fontSize="sm">LGA / District:</Text>
                                                <Heading as="h6" size="xs">{state.lga}</Heading>
                                                {/* <Text fontSize="sm">Address:</Text>
                                                <Heading as="h6" size="xs">Bwari, Abuja</Heading> */}
                                                <Text fontSize="sm">Size:</Text>
                                                <Heading as="h6" size="xs">{state.size ? kFormatter(parseFloat(state.size)) : ''}Ha</Heading>
                                            </SimpleGrid>
                                        </TabPanel>
                                        <TabPanel>
                                            <SimpleGrid columns={2} spacingY="10px">
                                                <Text fontSize="sm">State / Region:</Text>
                                                <Heading as="h6" size="xs">{state.returned_state}</Heading>
                                                <Text fontSize="sm">LGA / District:</Text>
                                                <Heading as="h6" size="xs">{state.returned_lga}</Heading>
                                                <Text fontSize="sm">Crop:</Text>
                                                <Heading as="h6" size="xs">{state.crop}</Heading>
                                                <Text fontSize="sm">Association:</Text>
                                                <Heading as="h6" size="xs">{state.partner}</Heading>
                                            </SimpleGrid>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </GridItem>
                            <GridItem colSpan={3}>
                                <Heading as="h5" size="sm">Weather Forecasts</Heading>
                                <br />
                                <Grid templateColumns="repeat(24, 1fr)" gap={4}>
                                    <GridItem colSpan={11}>
                                        <Stack spacing={2}>
                                            <Text>Today, {dayjs().format('DD MMM YYYY')}</Text>
                                            <HStack spacing={2}>
                                                <Icon as={WiRain} w={6} h={6} color="blue.200" />
                                                <Heading as="h6" size="xs" color="orange.300">{data?.current.temp ?? 0}&#8451;</Heading>
                                            </HStack>
                                            <Text>Feels like {data?.current.feels_like ?? 0}&#8451;</Text>
                                            <Flex justify="space-between">
                                                <Stack spacing={2}>
                                                    <Heading as="h6" size="xs">Dewpoint</Heading>
                                                    <Text>{data?.current.dew_point ?? 0}&#8451;</Text>
                                                </Stack>
                                                <Stack spacing={2}>
                                                    <Heading as="h6" size="xs">Wind</Heading>
                                                    <Text>{data?.current.wind_speed ?? 0}m/s</Text>
                                                </Stack>
                                                <Stack spacing={2}>
                                                    <Heading as="h6" size="xs">Humidity</Heading>
                                                    <Text>{data?.current.humidity ?? 0}%</Text>
                                                </Stack>
                                            </Flex>
                                        </Stack>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <Divider orientation="vertical" />
                                    </GridItem>
                                    <GridItem colSpan={12}>
                                        <Stack spacing={2}>
                                            <Heading as="h6" size="xs">Hourly</Heading>
                                            <Flex justify="space-between">
                                                {data?.hourly.slice(0, 4).map((item, i) => (
                                                    <Box textAlign="center" key={i}>
                                                        <Text>{dayjs(item.dt * 1000).format('h a')}</Text>
                                                        <Icon as={WiRain} w={6} h={6} color="blue.200" />
                                                        <HStack spacing={.5}>
                                                            <Icon as={WiRaindrop} w={4} h={4} color="blue.200" />
                                                            <Text fontSize="xs">{item.humidity}%</Text>
                                                        </HStack>
                                                    </Box>
                                                ))}
                                            </Flex>
                                        </Stack>
                                    </GridItem>
                                </Grid>
                                <br />
                                <Stack spacing={4}>
                                    <Heading as="h6" size="xs">Next 7 Days</Heading>
                                    <Flex justify="space-between">
                                        {data?.daily.map((item, i) => (
                                            <Box textAlign="center" key={i}>
                                                <Text fontSize="sm">{dayjs(item.dt * 1000).format('ddd')}</Text>
                                                <Text fontSize="sm">{dayjs(item.dt * 1000).format('DD/MM')}</Text>
                                                <Icon as={WiRain} w={6} h={6} color="blue.200" />
                                                <HStack spacing={.5}>
                                                    <Icon as={WiRaindrop} w={4} h={4} color="blue.200" />
                                                    <Text fontSize="xs">{item.humidity}%</Text>
                                                </HStack>
                                            </Box>
                                        ))}
                                    </Flex>
                                </Stack>
                            </GridItem>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Flex >
    )
}

export default FarmAnalysisMapView;