import React from 'react';
import ChakraTextArea from './TextArea';
import ChakraInput from './Input';
import ChakraNumberInput from './NumberInput';
import ChakraSelect from './Select';

function FormikControl(props) {
    const { control, ...rest } = props;
    switch (control) {
        case 'input': return <ChakraInput {...rest} />
        case 'textarea': return <ChakraTextArea {...rest} />
        case 'select': return <ChakraSelect {...rest} />
        case 'number': return <ChakraNumberInput {...rest} />
        case 'radio':
        case 'checkbox':
        case 'date':
        default: return null
    }

}

export default FormikControl;