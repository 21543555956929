import React, { useState, useContext, useEffect } from 'react';
import useSWR from 'swr';
import {
    Grid,
    GridItem,
    Text,
    Heading,
    SimpleGrid,
    Box,
    TabPanel,
    TabPanels,
    TabList,
    Tab,
    Tabs,
    Center,
    Spinner,
    Flex,
    Stack,
    Icon,
    HStack,
    Divider,
    Button,
} from '@chakra-ui/react';
import { LoadScript } from '@react-google-maps/api';
import * as dayjs from 'dayjs';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { WiRain, WiRaindrop } from 'react-icons/wi';
import { FiAlertTriangle } from 'react-icons/fi';
// import VerificationMap from '../../utils/maps/VerificationMap';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import config from '../../config';
import { getPoints } from './helpers/getPoints';
import { kFormatter } from '../../utils/kFormatter';
import { showToast } from '../../utils/toast';
import { Context } from '../../context/Context';
import { renderVerificationStatus, renderOverallStatus, renderAlternateVegetationCheck, renderOverlay } from '../verification/helpers/renderData';
import GeoTIFFOverlay from '../dashboard/GeoTIFFOverlay';


const fetcher = (url) => {
    return axios.get(url).then(res => res.data)
}

function SingleFarmAnalysis() {

    const { user } = useContext(Context);

    const { state } = useLocation()


    const { coord } = state
    const points = getPoints(coord)

    const latLng = points[0]

    const { lat, lng } = latLng

    const { data } = useSWR(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lng}&exclude={part}&appid=${config.weatherKey}&units=metric`, fetcher)
    const [loadingSatImage, setLoadingSatImage] = useState(false)
    const [renderError, setError] = useState("")
    const [satImage, setSatImage] = useState(null)
    const [skywatchImage, setSkywatchImage] = useState(null)

    const [providerIndex, setProviderIndex] = useState(0)
    const [continueView, setContinue] = useState(false)


    useEffect(() => {
        const getSatImage = async () => {
            const requestDate = state.date_of_interest ?? state.created
            const date = new Date(requestDate)
            const day = date.getDate()
            const month = date.getMonth() + 1
            const year = date.getFullYear()
            const data = {
                cluster_id: state._id,
                day,
                month,
                year
            }
            setLoadingSatImage(true)

            try {
                setError("")
                const res = await axios.post(`${config.baseUrl}/tie/sat_image/all`, data)
                if (res.data.status.code !== 100) {
                    showToast("error", res.data.status.desc)
                }
                else {
                    if (res.data.entity.error) {
                        setError(res.data.entity.error)
                        setSkywatchImage(res.data.entity.skywatch_image)
                    }
                    console.log(res.data.entity)

                    if (res.data.entity.image_url_tiff) {
                        setSatImage(res.data.entity.image_url_tiff)
                        setSkywatchImage(res.data.entity.skywatch_image)
                    }
                }
            }
            catch (e) {
                console.log(e)
                showToast("error", e.message)
            }
            finally {
                setLoadingSatImage(false)
            }
        }

        getSatImage()
    }, [state]);


    const renderItem = () => {
        if (loadingSatImage) {
            return (
                <Flex w="100%" h="100%" justify="center">
                    <Center>
                        <Spinner />
                    </Center>
                </Flex>
            )
        }
        if (!continueView && parseInt(state.returned_size) < 100) {
            return <Flex w="100%" h="100%" justify="center" textAlign="center">
                <div>
                    <div>
                        <Icon as={FiAlertTriangle} w={16} h={16} color="orange.500" />
                    </div>
                    <br />
                    <Box w="50%" textAlign="center" mx="auto">
                        <h4>This provider is recommended for farm sizes above 100 Hectares</h4>
                        <br />
                        <Button colorScheme="teal" onClick={() => setContinue(true)}>Continue</Button>
                    </Box>
                </div>
            </Flex >
        }
        if (renderError && providerIndex === 0) {
            return <Flex w="100%" h="100%" justify="center" textAlign="center">
                <div>
                    <div>
                        <Icon as={FiAlertTriangle} w={16} h={16} color="red.500" />
                    </div>
                    <br />
                    <Box w="50%" textAlign="center" mx="auto">
                        <h4>{renderError}</h4>
                    </Box>
                </div>
            </Flex >
        }
        if (!skywatchImage?.includes('http') && providerIndex === 1) {
            return <Flex w="100%" h="100%" justify="center" textAlign="center">
                <div>
                    <div>
                        <Icon as={FiAlertTriangle} w={16} h={16} color="orange.500" />
                    </div>
                    <br />
                    <Box w="50%" textAlign="center" mx="auto">
                        <h4>{skywatchImage}</h4>
                        <br />
                        <Button colorScheme="teal" onClick={() => retry()}>Retry</Button>
                    </Box>
                </div>
            </Flex >
        }
        return (
            <div style={{ width: '100%', height: '85%' }}>
                {providerIndex === 0 ? <GeoTIFFOverlay url={satImage} /> : <GeoTIFFOverlay url={skywatchImage} />}
            </div>
        )
    }



    const retry = async () => {
        const requestDate = state.date_of_interest ?? state.created
        const date = new Date(requestDate)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        const data = {
            cluster_id: state._id,
            day,
            month,
            year
        }
        setLoadingSatImage(true)

        try {
            setError("")
            setSkywatchImage(null)
            const res = await axios.post(`${config.baseUrl}/tie/skywatch`, data)
            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }
            else {

                if (res.data.entity.image_url_tiff) {
                    setSkywatchImage(res.data.entity.skywatch_image)
                }
            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
        finally {
            setLoadingSatImage(false)
        }
    }


    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Farm Analysis</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Heading as="h5" size="sm">Unique ID: {state._id}</Heading>
                    <br />
                    <Box h={"50vh"}>
                        <Tabs variant="soft-rounded" colorScheme="green" onChange={(i) => setProviderIndex(i)}>
                            <TabList>
                                <Tab>Provider 1</Tab>
                                <Tab>Provider 2</Tab>
                            </TabList>
                        </Tabs>
                        <br />
                        <LoadScript
                            googleMapsApiKey={config.mapsKey}
                        >
                            {renderItem()}
                        </LoadScript>
                        <br />
                        <br />
                    </Box>
                    <Box mt={10}>
                        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                            <GridItem colSpan={2}>
                                {/* <Heading as="h5" size="sm" mb={4}>Select Layers</Heading>
                                <br /> */}
                                <SimpleGrid columns={2} spacingY="10px">
                                    <Heading as="h5" size="sm">
                                        Overall Results:
                                    </Heading>
                                    {renderOverallStatus(state.overall_status)}
                                </SimpleGrid>
                                <br />
                                <Heading as="h6" size="xs" mb={4}>Actions</Heading>
                                <SimpleGrid columns={2} spacingY="20px">
                                    <Text fontSize="sm">Vegetation Check:</Text>
                                    <Box>
                                        {renderAlternateVegetationCheck(state.status)}
                                    </Box>
                                    <Text fontSize="sm">Within Country:</Text>
                                    <Box>
                                        {renderVerificationStatus(state.within_country)}
                                    </Box>
                                    <Text fontSize="sm">Within State / Region:</Text>
                                    <Box>
                                        {renderVerificationStatus(state.within_state)}
                                    </Box>
                                    <Text fontSize="sm">Within LGA / District:</Text>
                                    <Box>
                                        {renderVerificationStatus(state.within_lga)}
                                    </Box>
                                    <Text fontSize="sm">Overlay Check:</Text>
                                    <Box>
                                        {renderOverlay(state.overlay)}
                                    </Box>
                                </SimpleGrid>
                                <br />
                                <Tabs>
                                    <TabList>
                                        <Tab _selected={{ color: 'brand.primary', borderColor: 'brand.primary' }}>User Details</Tab>
                                        <Tab _selected={{ color: 'brand.primary', borderColor: 'brand.primary' }}>Returned Info</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <SimpleGrid columns={2} spacingY="10px">
                                                <Text fontSize="sm">State / Region:</Text>
                                                <Heading as="h6" size="xs">{state.state}</Heading>
                                                <Text fontSize="sm">LGA / District:</Text>
                                                <Heading as="h6" size="xs">{state.lga}</Heading>
                                                {/* <Text fontSize="sm">Address:</Text>
                                                <Heading as="h6" size="xs">Bwari, Abuja</Heading> */}
                                                <Text fontSize="sm">Size:</Text>
                                                <Heading as="h6" size="xs">{state.size ? kFormatter(parseFloat(state.size)) : ''}Ha</Heading>
                                            </SimpleGrid>
                                        </TabPanel>
                                        <TabPanel>
                                            <SimpleGrid columns={2} spacingY="10px">
                                                <Text fontSize="sm">State / Region:</Text>
                                                <Heading as="h6" size="xs">{state.returned_state}</Heading>
                                                <Text fontSize="sm">LGA / District:</Text>
                                                <Heading as="h6" size="xs">{state.returned_lga}</Heading>
                                                <Text fontSize="sm">Crop:</Text>
                                                <Heading as="h6" size="xs">{state.crop}</Heading>
                                                <Text fontSize="sm">Association:</Text>
                                                <Heading as="h6" size="xs">{state.partner}</Heading>
                                                <Text fontSize="sm">Size:</Text>
                                                <Heading as="h6" size="xs">{state.returned_size} Ha</Heading>
                                            </SimpleGrid>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </GridItem>
                            <GridItem colSpan={3}>
                                <Heading as="h5" size="sm">Weather Forecasts</Heading>
                                <br />
                                <Grid templateColumns="repeat(24, 1fr)" gap={4}>
                                    <GridItem colSpan={11}>
                                        <Stack spacing={2}>
                                            <Text>Today, {dayjs().format('DD MMM YYYY')}</Text>
                                            <HStack spacing={2}>
                                                <Icon as={WiRain} w={6} h={6} color="blue.200" />
                                                <Heading as="h6" size="xs" color="orange.300">{data?.current.temp ?? 0}&#8451;</Heading>
                                            </HStack>
                                            <Text>Feels like {data?.current.feels_like ?? 0}&#8451;</Text>
                                            <Flex justify="space-between">
                                                <Stack spacing={2}>
                                                    <Heading as="h6" size="xs">Dewpoint</Heading>
                                                    <Text>{data?.current.dew_point ?? 0}&#8451;</Text>
                                                </Stack>
                                                <Stack spacing={2}>
                                                    <Heading as="h6" size="xs">Wind</Heading>
                                                    <Text>{data?.current.wind_speed ?? 0}m/s</Text>
                                                </Stack>
                                                <Stack spacing={2}>
                                                    <Heading as="h6" size="xs">Humidity</Heading>
                                                    <Text>{data?.current.humidity ?? 0}%</Text>
                                                </Stack>
                                            </Flex>
                                        </Stack>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <Divider orientation="vertical" />
                                    </GridItem>
                                    <GridItem colSpan={12}>
                                        <Stack spacing={2}>
                                            <Heading as="h6" size="xs">Hourly</Heading>
                                            <Flex justify="space-between">
                                                {data?.hourly.slice(0, 4).map((item, i) => (
                                                    <Box textAlign="center" key={i}>
                                                        <Text>{dayjs(item.dt * 1000).format('h a')}</Text>
                                                        <Icon as={WiRain} w={6} h={6} color="blue.200" />
                                                        <HStack spacing={.5}>
                                                            <Icon as={WiRaindrop} w={4} h={4} color="blue.200" />
                                                            <Text fontSize="xs">{item.humidity}%</Text>
                                                        </HStack>
                                                    </Box>
                                                ))}
                                            </Flex>
                                        </Stack>
                                    </GridItem>
                                </Grid>
                                <br />
                                <Stack spacing={4}>
                                    <Heading as="h6" size="xs">Next 7 Days</Heading>
                                    <Flex justify="space-between">
                                        {data?.daily.map((item, i) => (
                                            <Box textAlign="center" key={i}>
                                                <Text fontSize="sm">{dayjs(item.dt * 1000).format('ddd')}</Text>
                                                <Text fontSize="sm">{dayjs(item.dt * 1000).format('DD/MM')}</Text>
                                                <Icon as={WiRain} w={6} h={6} color="blue.200" />
                                                <HStack spacing={.5}>
                                                    <Icon as={WiRaindrop} w={4} h={4} color="blue.200" />
                                                    <Text fontSize="xs">{item.humidity}%</Text>
                                                </HStack>
                                            </Box>
                                        ))}
                                    </Flex>
                                </Stack>
                            </GridItem>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Flex >
    )
}

export default SingleFarmAnalysis;