import React, { useState, useContext, useMemo } from 'react';
import {
    Box,
    Flex,
    Heading,
    HStack,
    Select,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Portal,
    PopoverArrow,
    PopoverCloseButton,
    Button,
    Grid,
    SimpleGrid, Text, GridItem, Tabs, TabPanel, Tab, TabPanels, TabList
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import * as dayjs from 'dayjs';
import { AiOutlineCalendar } from 'react-icons/ai';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';
import Can from '../../utils/rbac/Can';
import { useTenants } from '../tenants/helpers/fetcher';
import { usePartners, useProviders } from '../verification/helpers/fetcher';
import { useStates, useCrops } from '../../utils/data/fetcher';
import { useVerification } from '../verification/helpers/fetcher';
import InsightsMap from '../../utils/maps/InsightsMap';
import { renderVerificationStatus, renderOverallStatus, renderAlternateVegetationCheck, renderOverlay } from '../verification/helpers/renderData';
import { kFormatter } from '../../utils/kFormatter';


function MappingInsights() {
    const { user } = useContext(Context);

    const { role, tenant } = user;

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection',
            color: "#009688"
        }
    ]);
    const [selectedTenant, setSelectedTenant] = useState("")
    const [selectedPartner, setSelectedPartner] = useState("")
    const [selectedProvider, setSelectedProvider] = useState("")
    const [selectedState, setSelectedState] = useState("")
    const [selectedCrop, setSelectedCrop] = useState("")
    const [selectedStatus, setSelectedStatus] = useState("")
    const [polygonData, setData] = useState(null)

    const displayStartDate = dayjs(state[0].startDate).format('DD-MM-YY')
    const displayEndDate = dayjs(state[0].endDate).format('DD-MM-YY')


    const filterTenant = selectedTenant ? `&tenant=${selectedTenant}` : '';
    const filterPartner = selectedPartner ? `&partner=${selectedPartner}` : '';
    const filterProvider = selectedProvider ? `&provider=${selectedProvider}` : '';
    const filterStatus = selectedStatus ? `&overall_status=${selectedStatus}` : '';
    const filterState = selectedState ? `&state=${selectedState}` : '';
    const filterCrop = selectedCrop ? `&crop=${selectedCrop}` : ''
    const filterDateFrom = displayStartDate ? `&dateFrom=${displayStartDate}` : '';
    const filterDateTo = displayEndDate ? `&dateTo=${displayEndDate}` : '';

    const tenantState = tenant?.country ? `?country=${tenant.country}` : ''


    const filterPath = filterTenant + filterPartner + filterState + filterStatus + filterCrop + filterDateTo + filterDateFrom + filterProvider;

    const { data: tenantEntity } = useTenants(`tenants/tie`, user.token)
    const { data: partners } = usePartners(`tie/partners`, user.token)
    const { data: providers } = useProviders(`providers/tie`, user.token)
    const { states, } = useStates(`tie/states${tenantState}`, user.token)

    const { crops, } = useCrops("crops/tie", user.token)
    const { data: entity } = useVerification(`tie/data?per_page=${1000}${filterPath}`, user.token)


    const tenants = tenantEntity.tenants ?? []
    const data = useMemo(() => entity.aaData || [], [entity])

    const setActivePolygon = (data) => {
        setData(data)
    }

    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Mapping Insights</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <br />
                    <Flex justify="space-between">
                        <HStack spacing={2}>
                            <Can
                                role={role}
                                perform="tenants:visit"
                                yes={() => (
                                    <Select
                                        placeholder="Tenants"
                                        onChange={(e) => {
                                            setSelectedTenant(e.target.value)
                                        }}

                                        borderColor="brand.green"

                                    >
                                        <option value="">All</option>
                                        {
                                            tenants.map(item => (
                                                <option key={item._id} value={item._id}>{item.name}</option>
                                            ))
                                        }
                                    </Select>

                                )}
                                no={() => null}
                            />
                            <Select

                                borderColor="brand.green"
                                placeholder="Providers"
                                onChange={(e) => {
                                    setSelectedProvider(e.target.value)
                                }}
                            >
                                <option value="">All</option>
                                {
                                    providers.map((item, i) => (
                                        <option key={i} value={item.provider}>{item.provider}</option>
                                    ))
                                }
                            </Select>
                            <Select

                                borderColor="brand.green"
                                placeholder="Anchors"
                                onChange={(e) => {
                                    setSelectedPartner(e.target.value)
                                }}
                            >
                                <option value="">All</option>
                                {
                                    partners.map((item, i) => (
                                        <option key={i} value={item.partner}>{item.partner}</option>
                                    ))
                                }
                            </Select>
                            <Select
                                borderColor="brand.green"
                                placeholder="Result"
                                onChange={(e) => {
                                    setSelectedStatus(e.target.value)
                                }}
                            >
                                <option value="PASS">PASS</option>
                                <option value="FAIL">FAIL</option>
                                <option value="IN PROGRESS">IN PROGRESS</option>
                            </Select>
                            <Select
                                borderColor="brand.green"
                                placeholder="State / Region"
                                onChange={(e) => {
                                    setSelectedState(e.target.value)
                                }}
                            >
                                <option value="">All</option>
                                {states.map(state => (
                                    <option key={state.state} value={state.state}>{state.state}</option>
                                ))}
                            </Select>
                            <Select

                                borderColor="brand.green"
                                placeholder="Crop"
                                onChange={(e) => {
                                    setSelectedCrop(e.target.value)
                                }}
                            >
                                <option value="">All</option>
                                {crops.map((item, i) => (
                                    <option key={i} value={item.crop}>{item.crop}</option>
                                ))}
                            </Select>
                        </HStack>
                    </Flex>
                    <br />
                    <Flex justify="flex-end">
                        <Popover placement="auto-start">
                            <PopoverTrigger>
                                <Button variant="outline" rightIcon={<AiOutlineCalendar />}>
                                    {displayStartDate} - {displayEndDate}
                                </Button>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <DateRangePicker
                                        onChange={item => {
                                            setState([item.selection])
                                        }}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={state}
                                        direction="horizontal"
                                    />
                                </PopoverContent>
                            </Portal>

                        </Popover>
                    </Flex>
                    <br />
                    <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                        <GridItem colSpan={3}>
                            <InsightsMap data={data} setActivePolygon={setActivePolygon} />
                        </GridItem>
                        <GridItem colSpan={2}>
                            <SimpleGrid columns={2} spacingY="10px">
                                <Heading as="h5" size="sm">
                                    Overall Results:
                                </Heading>
                                <Box>
                                    {polygonData && renderOverallStatus(polygonData.overall_status)}
                                </Box>

                                <Heading as="h5" size="sm">Unique ID:</Heading>
                                <Heading as="h6" size="xs">{polygonData?._id}</Heading>
                            </SimpleGrid>
                            <br />
                            <Heading as="h6" size="xs" mb={4}>Actions</Heading>
                            <SimpleGrid columns={2} spacingY="20px">
                                <Text fontSize="sm">Vegetation Check:</Text>
                                <Box>
                                    {polygonData && renderAlternateVegetationCheck(polygonData.status)}
                                </Box>
                                <Text fontSize="sm">Within Country:</Text>
                                <Box>
                                    {polygonData && renderVerificationStatus(polygonData.within_country)}
                                </Box>
                                <Text fontSize="sm">Within State / Region:</Text>
                                <Box>
                                    {polygonData && renderVerificationStatus(polygonData.within_state)}
                                </Box>
                                <Text fontSize="sm">Within LGA / District:</Text>
                                <Box>
                                    {polygonData && renderVerificationStatus(polygonData.within_lga)}
                                </Box>
                                <Text fontSize="sm">Overlay Check:</Text>
                                <Box>
                                    {polygonData && renderOverlay(polygonData.overlay)}
                                </Box>
                            </SimpleGrid>
                            <br />
                            <Tabs>
                                <TabList>
                                    <Tab _selected={{ color: 'brand.primary', borderColor: 'brand.primary' }}>User Details</Tab>
                                    <Tab _selected={{ color: 'brand.primary', borderColor: 'brand.primary' }}>Returned Info</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <SimpleGrid columns={2} spacingY="10px">
                                            <Text fontSize="sm">State / Region:</Text>
                                            <Heading as="h6" size="xs">{polygonData?.state}</Heading>
                                            <Text fontSize="sm">LGA / District:</Text>
                                            <Heading as="h6" size="xs">{polygonData?.lga}</Heading>
                                            {/* <Text fontSize="sm">Address:</Text>
                                            <Heading as="h6" size="xs">Bwari, Abuja</Heading> */}
                                            <Text fontSize="sm">Size (Ha):</Text>
                                            <Heading as="h6" size="xs">{polygonData?.size ? kFormatter(parseFloat(polygonData.size)) : ''}</Heading>
                                            <Text fontSize="sm">Association:</Text>
                                            <Heading as="h6" size="xs">{polygonData?.partner}</Heading>
                                            <Text fontSize="sm">Crop:</Text>
                                            <Heading as="h6" size="xs">{polygonData?.crop}</Heading>
                                            <Text fontSize="sm">Cluster:</Text>
                                            <Heading as="h6" size="xs">{polygonData?.cluster_name}</Heading>
                                        </SimpleGrid>
                                    </TabPanel>
                                    <TabPanel>
                                        <SimpleGrid columns={2} spacingY="10px">
                                            <Text fontSize="sm">State / Region:</Text>
                                            <Heading as="h6" size="xs">{polygonData?.returned_state}</Heading>
                                            <Text fontSize="sm">LGA / District:</Text>
                                            <Heading as="h6" size="xs">{polygonData?.returned_lga}</Heading>

                                        </SimpleGrid>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                            <br />
                            {polygonData &&
                                <Link
                                    to={{
                                        pathname: "/dashboard/farm-analysis",
                                        state: polygonData
                                    }}
                                >
                                    <Button colorScheme="teal" size="md">Analyze Farm</Button>
                                </Link>

                            }
                        </GridItem>
                    </Grid>

                </Box>
            </Box>

        </Flex >

    )
}

export default MappingInsights;