import React, { useContext } from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

import Login from './views/auth/Login';
import Users from './views/users/Users';
import AddUser from './views/users/AddUser';
// import Dashboard from './views/dashboard/Dashboard';
import { Helmet } from 'react-helmet';
import VerificationList from './views/verification/VerificationList';
import VerificationDetails from './views/verification/VerificationDetails';
import Tenants from './views/tenants/Tenants';
import AddTenant from './views/tenants/AddTenant';
import Developer from './views/settings/Developer';
import Configuration from './views/settings/Configuration';

import EditProfile from './views/profile/Profile';
import EditTenant from './views/tenants/EditTenant';
import { Context } from './context/Context';
import './App.css';
import Downloads from './views/download/Downloads';
import VegetationCheckList from './views/vegetationCheck/VegetationCheckList';
import VegetatonCheck from './views/vegetationCheck/VegetationCheck';
import FarmAnalysis from './views/dashboard/FarmAnalysis';
import EditUser from './views/users/EditUser';
import SettlementCheckList from './views/settlementCheck/SettlementCheckList';
import SettlementCheck from './views/settlementCheck/SettlementCheck';
import DashIndex from './views/dashboard/DashIndex';
import MappingInsights from './views/dashboard/MappingInsights';
import Report from './views/report/Report';
import { useTenant } from './views/tenants/helpers/fetcher';
import Tiff from './views/dashboard/tifview';
import Countries from './views/countries/Countries';
import AddCountry from './views/countries/AddCountry';
import Currencies from './views/currencies/Currencies';
import AddCurrency from './views/currencies/AddCurrency';
import QuickVerification from './views/verification/QuickVerification';
import SingleFarmAnalysis from './views/verification/SingleFarmAnalysis';



function App() {
  const { user } = useContext(Context);

  const { hostname } = window.location
  const { data: tenant, isLoading } = useTenant(`tenant/domain/${hostname}/tie`)


  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          user.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  const UnAuthRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !user.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: location }
              }}
            />
          )
        }
      />
    )
  }
  return (
    <div style={{ color: '#6C757D' }}>
      <Helmet>
        {!isLoading &&
          <title>{tenant?.name ?? 'Tradeuza Intelligence Engine'}</title>
        }
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route exact path="/tiff">
            <Tiff />
          </Route>
          <UnAuthRoute exact path="/login">
            <Login tenant={tenant} />
          </UnAuthRoute>
          <PrivateRoute exact path="/dashboard">
            <DashIndex />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard/mapping-insights">
            <MappingInsights />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard/farm-analysis">
            <FarmAnalysis />
          </PrivateRoute>
          <PrivateRoute exact path="/settlement-check">
            <SettlementCheckList />
          </PrivateRoute>
          <PrivateRoute exact path="/settlement-check/:id">
            <SettlementCheck />
          </PrivateRoute>
          <PrivateRoute exact path="/users">
            <Users />
          </PrivateRoute>
          <PrivateRoute exact path="/users/add">
            <AddUser />
          </PrivateRoute>
          <PrivateRoute exact path="/users/edit/:id">
            <EditUser />
          </PrivateRoute>
          <PrivateRoute exact path="/verification">
            <VerificationList />
          </PrivateRoute>
          <PrivateRoute exact path="/verification/:id">
            <VerificationDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/quick-verification">
            <QuickVerification />
          </PrivateRoute>
          <PrivateRoute exact path="/quick-verification/farm-analysis">
            <SingleFarmAnalysis />
          </PrivateRoute>
          <PrivateRoute exact path="/tenants">
            <Tenants />
          </PrivateRoute>
          <PrivateRoute exact path="/tenants/add">
            <AddTenant />
          </PrivateRoute>
          <PrivateRoute exact path="/tenants/edit/:id">
            <EditTenant />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/developer">
            <Developer />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/configuration">
            <Configuration />
          </PrivateRoute>
          <PrivateRoute exact path="/profile/edit">
            <EditProfile />
          </PrivateRoute>
          <PrivateRoute exact path="/downloads">
            <Downloads />
          </PrivateRoute>
          <PrivateRoute exact path="/vegetation-check">
            <VegetationCheckList />
          </PrivateRoute>
          <PrivateRoute exact path="/vegetation-check/:id">
            <VegetatonCheck />
          </PrivateRoute>
          <PrivateRoute exact path="/report">
            <Report />
          </PrivateRoute>
          <PrivateRoute exact path="/countries">
            <Countries />
          </PrivateRoute>
          <PrivateRoute exact path="/countries/add">
            <AddCountry />
          </PrivateRoute>
          <PrivateRoute exact path="/currencies">
            <Currencies />
          </PrivateRoute>
          <PrivateRoute exact path="/currencies/add">
            <AddCurrency />
          </PrivateRoute>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
