import React, { useContext, useState } from 'react';
import { Formik, FieldArray, Field, Form } from 'formik';
import * as dayjs from 'dayjs'
import { Button, Input, Flex, Box, Heading, Grid } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import FormikControl from '../../utils/form/FormikControl';
import { quickVerificationSchema } from './helpers/validation';
import config from '../../config'
import { showToast } from '../../utils/toast';
import { Context } from '../../context/Context';
import { useStates2 } from '../../utils/data/fetcher';
import { getPoints } from './helpers/getPoints';


const initialValues = {
    // points: [
    //     { lat: "", lng: "" },
    //     { lat: "", lng: "" },
    //     { lat: "", lng: "" },
    //     { lat: "", lng: "" }
    // ],
    crop: "",
    group: "",
    source: "",
    date: "",
    size: "",
    cluster_name: "",
    state: "",
    lga: "",
    points: [
        { lat: 11.28003299, lng: 10.19680425 },
        { lat: 11.26093298, lng: 10.1945108 },
        { lat: 11.25923118, lng: 10.17154621 },
        { lat: 11.27775617, lng: 10.16632878 },
        { lat: 11.29903374, lng: 10.19081486 },
        { lat: 11.30233287, lng: 10.19905092 }
    ]
}


// i - This provider is recommended for farm sizes above 100Hectares


const QuickVerification = () => {

    const history = useHistory()

    const { user } = useContext(Context)

    const [selectedLgas, setSelectedLgas] = useState([])

    const requestOptions = {
        headers: { "Authorization": `Bearer live_tZWrYcZqchZqYIeVuRMHjoaIFQCyDi` },
    }


    const { states, } = useStates2(`states?country=NG`, user.token)


    const findAndSetLGA = (state) => {
        const filteredState = states.states.filter(item => item.lgas?.length)
        const foundState = filteredState.find(item => item.name === state)

        setSelectedLgas(foundState.lgas)
    }



    // const logPoints = () => {
    //     const points = getPoints("11.28003299:10.19680425,11.26093298:10.1945108,11.25923118:10.17154621,11.27775617:10.16632878,11.29903374:10.19081486,11.30233287:10.19905092")
    //     console.log(points)
    // }

    const onSubmit = async (values, onSubmitProps) => {


        if (!values.points[0].lat || !values.points[0].lng) {
            showToast("error", "Please fill in at least one set of latitude and longitude")
            return;
        }


        const { crop, source, group, size, date, cluster_name, state, lga } = values
        const coordinates = values.points.map(point => {
            const str = `${point.lat}:${point.lng}`;
            return str
        })


        const body = {
            unique_id: uuidv4(),
            coordinates: coordinates.join(),
            crop,
            source,
            group,
            size,
            date_of_interest: date,
            state,
            lga,
            cluster_name,
            overlay_check: true,
            vegetative_check: true,
            waterbody_check: true,
            groundwaterpotential_check: true,
            country: "Nigeria",
            lat: values.points[0].lat,
            long: values.points[0].lng,

        }
        try {
            const res = await axios.post(`${config.baseUrl}/tie`, body, requestOptions)
            if (res.data.status.code !== 100) {
                console.log(res)
                showToast("error", res.data.status.desc)
            }

            else {
                console.log(res)
                showToast("success", "Successfully submitted data")
                //go to new farm analysis
                history.push('/verification')
            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }
    return (

        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Quick Verification</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="rgb(255,255,255,0.55)" p={5} rounded="lg" mt={5}>
                    <div>
                        <p>Add 3 or more coordinates (Latitude and Longitude) to map to a polygon</p>
                        <br />
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={quickVerificationSchema}
                        >
                            {(formik) => (
                                <Form>
                                    <FieldArray
                                        name="points"
                                        render={arrayHelpers => (
                                            <div>
                                                {formik.values.points && formik.values.points.length > 0 ? (
                                                    formik.values.points.map((_, index) => (
                                                        <div key={index} style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
                                                            <p style={{ marginRight: 10 }}>{index + 1}</p>
                                                            <Field name={`points[${index}].lat`}>

                                                                {(props) => {
                                                                    const { field } = props
                                                                    return (
                                                                        <Input border="0"
                                                                            bg="white"
                                                                            focusBorderColor="brand.green"
                                                                            {...field} style={{ width: '40%' }} placeholder="Latitude" type="number" />
                                                                    )
                                                                }}
                                                            </Field>
                                                            <div style={{ width: 10 }}></div>
                                                            <Field name={`points[${index}].lng`}>
                                                                {(props) => {
                                                                    const { field } = props
                                                                    return (
                                                                        <Input
                                                                            border="0"
                                                                            bg="white"
                                                                            focusBorderColor="brand.green"  {...field} style={{ width: '40%' }} placeholder="Longitude" type="number" />
                                                                    )
                                                                }}
                                                            </Field>
                                                            <Button style={{ marginLeft: 10 }} variant="outline" colorScheme="red" onClick={() => arrayHelpers.remove(index)}>-</Button>
                                                            <Button style={{ marginLeft: 10 }} colorScheme="teal" onClick={() => arrayHelpers.push({ lat: "", lng: "" })} type="primary">+</Button>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <Button onClick={() => arrayHelpers.push({ lat: "", lng: "" })} colorScheme="teal">Add Coordinates</Button>
                                                )}
                                            </div>
                                        )}
                                    />
                                    <br />


                                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                        <Box w="100%">
                                            <FormikControl
                                                control="select"
                                                label="Select State"
                                                name="state"
                                                placeholder="Select State"
                                                optionName="name"
                                                value="name"
                                                isLoading={false}
                                                bg="white"
                                                options={states?.states?.filter(item => item.lgas?.length) ?? []}
                                                onChange={(e) => {
                                                    findAndSetLGA(e.target.value)
                                                }}
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="select"
                                                label="Select LGA"
                                                name="lga"
                                                placeholder="Select LGA"
                                                optionName="local_name"
                                                value="local_name"
                                                isLoading={false}
                                                bg="white"
                                                options={selectedLgas}

                                            />
                                        </Box>
                                    </Grid>

                                    <br />

                                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="number"
                                                label="Farm Size (Ha)"
                                                name="size"
                                                bg="white"
                                                placeholder="Farm Size"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="date"
                                                min={dayjs().format('YYYY-MM-DD')}
                                                max={dayjs().add(5, 'day').format('YYYY-MM-DD')}
                                                label="Date"
                                                name="date"
                                                bg="white"
                                                placeholder="Date"
                                                required={false}

                                            />
                                        </Box>
                                    </Grid>
                                    <br />
                                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                label="Source"
                                                name="source"
                                                bg="white"
                                                placeholder="Source"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                label="Group"
                                                name="group"
                                                bg="white"
                                                placeholder="Group"

                                            />
                                        </Box>
                                    </Grid>
                                    <br />

                                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                label="Crop"
                                                name="crop"
                                                bg="white"
                                                placeholder="Crop"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                label="Cluster Name"
                                                name="cluster_name"
                                                bg="white"
                                                placeholder="Cluster Name"

                                            />
                                        </Box>
                                    </Grid>


                                    <div style={{ marginTop: 40 }}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            style={{ marginRight: 10 }}
                                            colorScheme="teal"
                                            onClick={formik.handleSubmit}>Submit</Button>
                                        <Button variant="outline" colorScheme="red" onClick={() => {
                                            formik.resetForm()
                                        }}>Reset</Button>

                                    </div>
                                    {/* <div style={{ marginTop: 40 }}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            style={{ marginRight: 10 }}
                                            colorScheme="teal"
                                            onClick={() => {
                                                console.log(formik.values.points.some(x => (x.lat === '' || x.lng === '')))
                                            }}>Check</Button>

                                    </div> */}

                                </Form>)}
                        </Formik>
                    </div>


                </Box>


            </Box>

        </Flex >
    );
}

export default QuickVerification;