import React, { useContext } from 'react';
import { Box, Grid, Stack, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import axios from 'axios';
import { Context } from '../../context/Context';
import FormikControl from '../../utils/form/FormikControl';
import { showToast } from '../../utils/toast';
import { changePasswordValidationSchema } from './helpers/validationschemas';
import config from '../../config'



const initialValues = {
    old_password: '',
    password: '',
    confirm_password: '',
}

function ChangePassword() {
    const { user } = useContext(Context);

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    async function onSubmit(values, onSubmitProps) {
        try {
            const res = await axios.put(`${config.baseUrl}/account/password/tie`, values, requestOptions)
            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }

            else {
                showToast("success", "Successfully changed password")
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
            onSubmitProps.resetForm()
        }
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={changePasswordValidationSchema}
        >
            {formik => (
                <Form>
                    <Stack spacing={8}>
                        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                            <Box w="100%">
                                <FormikControl
                                    control="input"
                                    type="password"
                                    label="Old Password"
                                    name="old_password"
                                    placeholder="Old Password"
                                    required
                                />
                            </Box>
                            <Box w="100%">
                                <FormikControl
                                    control="input"
                                    type="password"
                                    label="New Password"
                                    name="password"
                                    placeholder="New Password"
                                    required
                                />
                            </Box>
                        </Grid>

                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <Box w="100%">
                                <FormikControl
                                    control="input"
                                    type="password"
                                    label="Confirm Password"
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                    required
                                />
                            </Box>
                        </Grid>
                    </Stack>
                    <Box mt={8}>
                        <Button
                            disabled={formik.isSubmitting}
                            isLoading={formik.isSubmitting}
                            loadingText="Submitting"
                            type="submit"
                            bg="brand.primary"
                            color="white"
                            _hover={{ bg: 'brand.green' }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}

export default ChangePassword;