import React, { useContext } from 'react';
import {
    Box,
    Flex,
    Heading,
    Grid, GridItem,
    VStack, Avatar, Text, Tabs, Tab, TabList, TabPanels, TabPanel
} from "@chakra-ui/react";
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';

function Profile() {
    const { user } = useContext(Context)


    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Edit Profile</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Grid
                        templateColumns="repeat(6, 1fr)"
                        gap={4}
                    >
                        <GridItem colSpan={2}>
                            <Box>
                                <VStack spacing={2}>
                                    <Avatar size="2xl" name={`${user.first_name} ${user.last_name}`} />
                                    <Heading as="h5" size="sm">{user.first_name} {user.last_name}</Heading>
                                    <Heading as="h6" size="xs" color="brand.primary">{user.role}</Heading>
                                    <Text fontSize="xs">{user.mobile}</Text>
                                    <Text fontSize="xs">{user.email}</Text>
                                </VStack>
                            </Box>
                        </GridItem>
                        <GridItem colSpan={4}>
                            <Tabs variant="unstyled">
                                <TabList>
                                    <Flex p={2} bg="brand.primary" w="100%" rounded="md">
                                        <Tab color="white" _selected={{ color: "text", bg: "white" }} rounded="md">Edit Profile</Tab>
                                        <Tab color="white" _selected={{ color: "text", bg: "white" }} rounded="md">Change Password</Tab>
                                    </Flex>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <EditProfile />
                                    </TabPanel>
                                    <TabPanel>
                                        <ChangePassword />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </GridItem>
                    </Grid>
                </Box>
            </Box>

        </Flex >

    )
}

export default Profile;