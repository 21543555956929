import React from 'react';
import { Tag, TagLabel, Kbd, Heading } from '@chakra-ui/react';

export const renderSettlementCheck = (value) => {
    if (value === "YES") {
        return (
            <Tag size="md" colorScheme="green" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )

    }
    else if (value === "NO") {
        return (
            <Tag size="md" colorScheme="red" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )
    }
    else if (!value) {
        return null
    }
    else {
        return (
            <Tag size="md" colorScheme="orange" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )
    }

}


export const renderVegetationCheck = (value) => {
    if (value === "PASS") {
        return (
            <Tag size="md" colorScheme="green" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )

    }
    else if (value === "FAIL") {
        return (
            <Tag size="md" colorScheme="red" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )
    }
    else if (!value) {
        return null
    }
    else {
        return (
            <Tag size="md" colorScheme="orange" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )
    }

}


export const renderOverlay = (value) => {
    if (value === "PASS") {
        return (
            <Tag size="md" colorScheme="green" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )

    }
    else if (value === "FAIL") {
        return (
            <Tag size="md" colorScheme="red" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )
    }
    else if (!value) {
        return null
    }
    else {
        return (
            <Tag size="md" colorScheme="orange" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )
    }
}


export const renderOverallStatus = (value) => {
    if (!value) {
        return <Heading></Heading>
    }

    if (value === "PASS") {
        return (
            <Heading as="h6" size="xs" color="green.500"> {value} </Heading>
        )

    }
    else if (value === "FAIL") {
        return (
            <Heading as="h6" size="xs" color="red.500"> {value} </Heading>
        )
    }
    else {
        return (
            <Heading as="h6" size="xs" color="orange.300"> {value} </Heading>
        )
    }

}


export const renderAlternateVegetationCheck = (value) => {
    if (value === "YES") {
        return (
            <Tag size="md" colorScheme="green" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )

    }
    else if (value === "NO") {
        return (
            <Tag size="md" colorScheme="red" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )
    }
    else {
        return (
            <Tag size="md" colorScheme="orange" borderRadius="full">
                <TagLabel>{value}</TagLabel>
            </Tag>
        )
    }

}


export const renderVerificationStatus = (value) => {
    if (value === "true") {
        return (
            <Tag size="md" colorScheme="green" borderRadius="full">
                <TagLabel>{value.toUpperCase()}</TagLabel>
            </Tag>
        )

    }
    else if (value === "false") {
        return (
            <Tag size="md" colorScheme="red" borderRadius="full">
                <TagLabel>{value.toUpperCase()}</TagLabel>
            </Tag>
        )
    }
    else {
        return (
            <Tag size="md" colorScheme="orange" borderRadius="full">
                <TagLabel>{value?.toUpperCase()}</TagLabel>
            </Tag>
        )
    }
}

export const renderGenericTrueFalse = (value) => {
    if (value === "true") {
        return (
            <Tag size="sm" colorScheme="green" borderRadius="full">
                {value.toUpperCase()}
            </Tag>
        )

    }
    else if (value === "false") {
        return (
            <Tag size="sm" colorScheme="red" borderRadius="full">
                {value.toUpperCase()}
            </Tag>
        )
    }
    else {
        return (
            <Tag size="sm" colorScheme="orange" borderRadius="full">
                {value.toUpperCase()}
            </Tag>
        )
    }
}

export const renderGenericPassFail = (value) => {
    if (value === "PASS") {
        return (
            <Tag size="sm" colorScheme="green" borderRadius="full">
                {/* {value} */}
                <TagLabel>{value}</TagLabel>
            </Tag>
        )

    }
    else if (value === "FAIL") {
        return (
            <Tag size="sm" colorScheme="red" borderRadius="full">
                {value}
            </Tag>
        )
    }
    else if (!value) {
        return null
    }
    else {
        return (
            <Tag size="sm" colorScheme="orange" borderRadius="full">
                {value}
            </Tag>
        )
    }

}


export const renderCoordinates = (value) => {
    const coordPair = value.split(",")
    return coordPair.map((coord, i) => <div key={i}>
        <Kbd>{coord}</Kbd>
    </div>)

}