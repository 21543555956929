const rules = {
    "SuperAdmin": {
        static: [
            "user-management:visit",
            "user:create",
            "tenants:visit",
            "tenant:add:edit",
            "dashboard:visit",
            "verification:visit",
            "verification:upload",
            "dashboard:mapping:visit",
            "dashboard:analysis:visit",
            "settings:visit",
            "settings:configuration",
            "report:visit",
            "countries:visit",
            "currencies:visit",
            "quickVerification:visit"
        ]
    },
    Admin: {
        static: [
            "user-management:visit",
            "user:create",
            "verification:visit",
            "dashboard:mapping:visit",
            "dashboard:analysis:visit",
            "dashboard:visit",
            "settings:visit",
            "settings:developer",
            "settings:configuration",
            // "downloads:visit"
            "report:visit",
            "quickVerification:visit"

        ],
        dynamic: {

        }
    },
    'RSE Agent': {
        static: [
            "vegetation:visit"
        ]
    },
};

export default rules;




