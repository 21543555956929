import React, { useContext, useState, useMemo } from 'react';
import {
    Box,
    Button,
    Flex,
    Text,
    Heading,
    Stack,
    Input,
    InputLeftElement,
    InputGroup,
    Icon,
    HStack,
    VStack,
    IconButton,
    Divider,
    Spinner,
} from "@chakra-ui/react";
import { useTable } from 'react-table'
import { AiOutlineEdit, AiOutlineSearch } from 'react-icons/ai'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useHistory } from 'react-router-dom';
import { RiDeleteBin6Line } from 'react-icons/ri'
import Drawer from '../layout/Drawer';
import { Context } from '../../context/Context';
import { useTenants } from './helpers/fetcher';
import ProfileHeader from '../layout/ProfileHeader'


function Tenants() {
    const history = useHistory()
    const { user } = useContext(Context)
    const [pageIndex, setPageIndex] = useState(0)
    const [limit] = useState(10)

    const filterPage = pageIndex > 0 ? `&page=${pageIndex}` : '';

    const filterPath = filterPage

    const { data: entity, isLoading } = useTenants(`tenants/tie?per_page=${limit}${filterPath}`, user.token)


    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",

            },
            {
                Header: "Description",
                accessor: "description"
            },
            {
                Header: "Tenant ID",
                accessor: "tenant_id"
            },

            {
                Header: "Actions",
                Cell: ({ row }) => {
                    const { original } = row
                    return (
                        <HStack spacing={6}>
                            <Button variant="ghost" onClick={() => history.push(`/tenants/edit/${original._id}`, original)}>
                                <AiOutlineEdit style={{ fontSize: 18 }} />
                            </Button>
                            <Button variant="ghost">
                                <RiDeleteBin6Line style={{ fontSize: 18 }} />
                            </Button>
                        </HStack>
                    )

                }

            },
        ], [history]
    )
    const data = useMemo(() => entity.tenants || [], [entity])

    const tableInstance = useTable({ columns, data })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    const setPrev = () => {
        setPageIndex(pageIndex - 1)
    }

    const setNext = () => {
        setPageIndex(pageIndex + 1)
    }


    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Tenants</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Stack spacing={4}>
                        <Heading as="h5" size="sm">All Tenants</Heading>
                        <Flex justify="between">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<Icon as={AiOutlineSearch} color="gray.500" />}
                                />
                                <Input type="phone" placeholder="Search" w={60} />
                            </InputGroup>
                            <Stack spacing={4} direction="row" align="center">
                                <Button onClick={() => history.push('/tenants/add')} colorScheme="teal" size="md">
                                    <Text fontWeight="normal">Add New Tenant</Text>
                                </Button>
                            </Stack>
                        </Flex>
                    </Stack>
                    <Box mt={4} overflow="auto">
                        <table {...getTableProps()}>
                            <thead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (

                                                    <th {...column.getHeaderProps()}>
                                                        {
                                                            column.render('Header')}
                                                    </th>
                                                ))}
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {// Loop over the table rows
                                    rows.map(row => {
                                        // Prepare the row for display
                                        prepareRow(row)
                                        return (
                                            // Apply the row props
                                            <tr {...row.getRowProps()}>
                                                {// Loop over the rows cells
                                                    row.cells.map(cell => {
                                                        // Apply the cell props
                                                        return (
                                                            <td {...cell.getCellProps()}>
                                                                {// Render the cell contents
                                                                    cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}

                            </tbody>
                        </table>
                        {isLoading &&
                            <VStack mt={4}>
                                <Spinner color="brand.green" />
                            </VStack>}
                        {!isLoading && entity.tenants?.length === 0 && <Text textAlign="center">No records found</Text>}
                    </Box>

                    <Flex justify="flex-end" mt={4}>
                        <HStack spacing={2}>
                            {/* <Menu>
                                <MenuButton as={Button} variant="ghost" rightIcon={<BiChevronDown />}>
                                    <Text fontSize="sm">Rows per Page: {limit}</Text>
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => setLimit(10)}>10</MenuItem>
                                    <MenuItem onClick={() => setLimit(20)}>20</MenuItem>
                                    <MenuItem onClick={() => setLimit(50)}>50</MenuItem>
                                    <MenuItem onClick={() => setLimit(100)}>100</MenuItem>
                                </MenuList>
                            </Menu> */}

                            <Divider orientation="vertical" />
                            <IconButton variant="ghost" disabled={pageIndex <= 0} onClick={setPrev} aria-label="Previous Page" icon={<FiChevronLeft />} size="xs" color="brand.primary" />
                            <Text fontSize="sm"> Page {pageIndex + 1} of {entity ? entity.total_pages : '-'}</Text>
                            <IconButton variant="ghost" disabled={(pageIndex + 1) >= entity.total_pages} onClick={setNext} aria-label="Previous Page" icon={<FiChevronRight />} size="xs" color="brand.primary" />
                        </HStack>
                    </Flex>
                </Box>
            </Box>

        </Flex >

    )
}

export default Tenants;