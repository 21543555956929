import React, { useContext, useState } from 'react';
import { Flex, Box, Heading, HStack, Select, Grid, GridItem, Text, Progress, VStack, Icon, Tooltip, Image } from '@chakra-ui/react';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import Can from '../../utils/rbac/Can';
import { Context } from '../../context/Context';
import { useTenants } from '../tenants/helpers/fetcher';
import { usePartners, useProviders } from '../verification/helpers/fetcher';
import { useStates, useCrops } from '../../utils/data/fetcher';
import { BiCircle } from 'react-icons/bi';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import DoughnutChart from './charts/DoughnutChart';
import VegetationDoughnutChart from './charts/VegetationDoughnutChart';
import Irrigation from '../../assets/images/irrigation.svg';
import { useVerificationCards } from '../verification/helpers/fetcher';
import { useVegetationData, useOverlaydata } from '../vegetationCheck/helpers/fetcher';
import { useGeoData } from '../../utils/data/fetcher';
import { kFormatter } from '../../utils/kFormatter';




function Dashboard() {
    const { user } = useContext(Context);
    const { role, tenant } = user;

    const [selectedTenant, setSelectedTenant] = useState("")
    const [selectedPartner, setSelectedPartner] = useState("")
    const [selectedProvider, setSelectedProvider] = useState("")
    const [selectedState, setSelectedState] = useState("")
    const [selectedCrop, setSelectedCrop] = useState("")

    const filterTenant = selectedTenant ? `&tenant=${selectedTenant}` : '';
    const filterPartner = selectedPartner ? `&partner=${selectedPartner}` : '';
    const filterProvider = selectedProvider ? `&provider=${selectedProvider}` : '';
    const filterState = selectedState ? `&state=${selectedState}` : '';
    const filterCrop = selectedCrop ? `&crop=${selectedCrop}` : ''

    const tenantState = tenant?.country ? `?country=${tenant.country}` : ''

    const filterPath = filterTenant + filterPartner + filterState + filterCrop + filterProvider;

    const { data: tenantEntity } = useTenants(`tenants/tie`, user.token)
    const { data: partners } = usePartners(`tie/partners`, user.token)
    const { data: providers } = useProviders(`providers/tie`, user.token)
    const { states, } = useStates(`tie/states${tenantState}`, user.token)

    const { crops, } = useCrops("crops/tie", user.token)

    const tenants = tenantEntity.tenants ?? []

    const { data: pieData } = useVerificationCards(`dashboard/tie?data=0${filterPath}`, user.token)
    const { data: vegetationData } = useVegetationData(`tie/data/vegetation?data=0${filterPath}`, user.token)
    const { data: overlayData } = useOverlaydata(`tie/data/overlay?data=0${filterPath}`, user.token)
    const { data: countryData, } = useGeoData(`tie/data/country?data=0${filterPath}`, user.token)
    const { data: stateData, } = useGeoData(`tie/data/state?data=0${filterPath}`, user.token)
    const { data: lgaData, } = useGeoData(`tie/data/lga?data=0${filterPath}`, user.token)

    const trueCountry = countryData?.filter(data => data.within_country === "true")
    const falseCountry = countryData?.filter(data => data.within_country !== "true")

    const totalCountryTrue = trueCountry.reduce((acc, data) => acc + parseFloat(data.total_no), 0)
    const totalCountryFalse = falseCountry.reduce((acc, data) => acc + parseFloat(data.total_no), 0)
    const totalCountry = totalCountryTrue + totalCountryFalse
    const percentageCountry = ((totalCountryTrue / totalCountry) * 100).toFixed(0)

    const trueState = stateData.filter(data => data.within_state === "true")
    const falseState = stateData.filter(data => data.within_state !== "true")

    const totalStateTrue = trueState.reduce((acc, data) => acc + parseFloat(data.total_no), 0)
    const totalStateFalse = falseState.reduce((acc, data) => acc + parseFloat(data.total_no), 0)
    const totalState = totalStateTrue + totalStateFalse
    const percentageState = ((totalStateTrue / totalState) * 100).toFixed(0)



    const trueLga = lgaData.filter(data => data.within_lga === "true")
    const falseLga = lgaData.filter(data => data.within_lga !== "true")

    const totalLgaTrue = trueLga.reduce((acc, data) => acc + parseFloat(data.total_no), 0)
    const totalLgaFalse = falseLga.reduce((acc, data) => acc + parseFloat(data.total_no), 0)
    const totalLga = totalLgaTrue + totalLgaFalse
    const percentageLga = ((totalLgaTrue / totalLga) * 100).toFixed(0)


    const passed = pieData.filter(data => data.overall_status === "PASS")
    const failed = pieData.filter(data => data.overall_status === "FAIL")
    const pending = pieData.filter(data => data.overall_status !== "PASS" && data.overall_status !== "FAIL")
    const totalClusters = pieData.reduce((acc, data) => acc + parseInt(data.total_no), 0)
    const totalArea = pieData.reduce((acc, data) => acc + parseFloat(data.area_sum), 0)


    const yes = vegetationData.filter(data => data.status === "YES")
    const no = vegetationData.filter(data => data.status === "NO")
    const inProgress = vegetationData.filter(data => data.status !== "YES" && data.status !== "NO")

    const unique = overlayData.filter(data => data.overlay === "PASS")
    const intersecting = overlayData.filter(data => data.overlay === "FAIL")

    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Dashboard</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Box bg="brand.primary" px={4} py={2} rounded="md">
                        <HStack spacing={5}>
                            <Can
                                role={role}
                                perform="tenants:visit"
                                yes={() => (
                                    <Select
                                        placeholder="Tenants"
                                        onChange={(e) => {
                                            setSelectedTenant(e.target.value)
                                        }}
                                        bg="lightGrey"
                                        border="0"
                                        focusBorderColor="brand.green"

                                    >
                                        <option value="">All</option>
                                        {
                                            tenants.map(item => (
                                                <option key={item._id} value={item._id}>{item.name}</option>
                                            ))
                                        }
                                    </Select>

                                )}
                                no={() => null}
                            />
                            <Select
                                placeholder="Sources"
                                onChange={(e) => {
                                    setSelectedProvider(e.target.value)
                                }}
                                bg="lightGrey"
                                border="0"
                                focusBorderColor="brand.green"
                            >
                                <option value="">All</option>
                                {
                                    providers.map((item, i) => (
                                        <option key={i} value={item.provider}>{item.provider}</option>
                                    ))
                                }
                            </Select>
                            <Select
                                borderColor="brand.green"
                                placeholder="Organizations"
                                onChange={(e) => {
                                    setSelectedPartner(e.target.value)
                                }}
                                bg="lightGrey"
                                border="0"
                                focusBorderColor="brand.green"
                            >
                                <option value="">All</option>
                                {
                                    partners.map((item, i) => (
                                        <option key={i} value={item.partner}>{item.partner}</option>
                                    ))
                                }
                            </Select>
                            <Select
                                borderColor="brand.green"
                                placeholder="State / Region"
                                onChange={(e) => {
                                    setSelectedState(e.target.value)
                                }}
                                bg="lightGrey"
                                border="0"
                                focusBorderColor="brand.green"
                            >
                                <option value="">All</option>
                                {states.map(state => (
                                    <option key={state.state} value={state.state}>{state.state}</option>
                                ))}
                            </Select>
                            <Select
                                borderColor="brand.green"
                                placeholder="Crop"
                                onChange={(e) => {
                                    setSelectedCrop(e.target.value)
                                }}
                                bg="lightGrey"
                                border="0"
                                focusBorderColor="brand.green"
                            >
                                <option value="">All</option>
                                {crops.map((item, i) => (
                                    <option key={i} value={item.crop}>{item.crop}</option>
                                ))}
                            </Select>
                        </HStack>
                    </Box>

                    <br />
                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                        <GridItem>
                            <Heading as="h6" size="xs" color="gray.900">Sent by Service Provider</Heading>
                            <br />
                            <Box p={5} bg="white" borderBottomWidth={4} borderColor="#ddd">
                                <HStack spacing={2} align="flex-start" >
                                    <Icon as={BiCircle} w={6} h={6} color="red.300" />
                                    <VStack align="stretch">
                                        <Heading as="h3" size="lg" color="gray.900">{kFormatter(totalClusters) ?? 0}</Heading>
                                        <Heading as="h6" size="xs" color="gray.900">Number of Clusters</Heading>
                                    </VStack>
                                </HStack>
                            </Box>
                            <br />
                            <Box p={5} bg="white" borderBottomWidth={4} borderColor="#ddd">
                                <HStack spacing={2} align="flex-start">
                                    <Icon as={BiCircle} w={6} h={6} color="blue.300" />
                                    <VStack align="stretch">
                                        <Heading as="h3" size="lg" color="gray.900">{kFormatter(totalArea) ?? 0}</Heading>
                                        <Heading as="h6" size="xs" color="gray.900">Area of Clusters (Ha)</Heading>
                                    </VStack>
                                </HStack>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Heading as="h6" size="xs" color="gray.900">Final Result</Heading>
                            </Flex>

                            <br />
                            <DoughnutChart passed={passed} failed={failed} pending={pending} />
                            <br />
                            <Flex justify="space-around">
                                <VStack>
                                    <Heading as="h5" size="sm" color="gray.900">{kFormatter(parseFloat(passed[0]?.area_sum ?? 0))}</Heading>
                                    <Text fontSize="xs">Passed (Ha)</Text>
                                </VStack>
                                <VStack>
                                    <Heading as="h5" size="sm" color="gray.900">{kFormatter(parseFloat(failed[0]?.area_sum ?? 0))}</Heading>
                                    <Text fontSize="xs">Failed (Ha)</Text>
                                </VStack>
                                <VStack>
                                    <Heading as="h5" size="sm" color="gray.900">{kFormatter(parseFloat(pending[0]?.area_sum ?? 0))}</Heading>
                                    <Text fontSize="xs">In Progress (Ha)</Text>
                                </VStack>
                            </Flex>
                        </GridItem>
                    </Grid>
                    <br />
                    <br />
                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                        <GridItem>
                            <Box shadow="md" p={5} bg="#fafafa" rounded="md">
                                <Heading as="h6" size="xs" color="gray.900">Overlay Checks</Heading>
                                <br />
                                <Flex justify="space-between">
                                    <VStack align="flex-start">
                                        <Heading as="h3" size="lg" color="gray.900">{unique[0]?.total_no ?? 0}</Heading>
                                        <Heading as="h6" size="xs" color="gray.900">UNIQUE CLUSTERS</Heading>
                                    </VStack>
                                    <Box w={1} bg="#ddd" />
                                    {/* <Divider orientation="vertical" /> */}
                                    <VStack align="flex-start">
                                        <Heading as="h3" size="lg" color="gray.900">{intersecting[0]?.total_no ?? 0}</Heading>
                                        <Heading as="h6" size="xs" color="gray.900">INTERSECTING CLUSTERS</Heading>
                                    </VStack>
                                </Flex>
                            </Box>

                        </GridItem>
                        <GridItem>
                            <Box shadow="md" p={5} bg="white" rounded="md">
                                <Heading as="h6" size="xs" color="gray.900">LOCATION CHECKS</Heading>
                                <br />
                                <Flex justify="space-between" align="center" mb={4}>
                                    <Box w="30%">
                                        <Heading as="h6" size="xs" color="gray.900">IN COUNTRY</Heading>
                                    </Box>
                                    <Box w="60%">
                                        <Progress value={percentageCountry || 0} size="xs" colorScheme="purple" />
                                    </Box>
                                    <Text fontSize="sm">{percentageCountry || 0}%</Text>
                                </Flex>
                                <Flex justify="space-between" align="center" mb={4}>
                                    <Box w="30%">
                                        <Heading as="h6" size="xs" color="gray.900">IN STATE / REGION</Heading>
                                    </Box>
                                    <Box w="60%">
                                        <Progress value={percentageState || 0} size="xs" colorScheme="teal" />
                                    </Box>
                                    <Text fontSize="sm">{percentageState || 0}%</Text>
                                </Flex>
                                <Flex justify="space-between" align="center">
                                    <Box w="30%">
                                        <Heading as="h6" size="xs" color="gray.900">IN LGA / DISTRICT:</Heading>
                                    </Box>
                                    <Box w="60%">
                                        <Progress value={percentageLga || 0} size="xs" colorScheme="orange" />
                                    </Box>
                                    <Text fontSize="sm">{percentageLga || 0}%</Text>
                                </Flex>
                            </Box>
                        </GridItem>
                    </Grid>
                    <br />
                    <br />
                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                        {role === "SuperAdmin" && <GridItem>
                            <Box shadow="md" p={5} bg="#fafafa" rounded="md">
                                <Heading as="h6" size="xs" color="gray.900">Access to Irrigation</Heading>
                                <Text fontSize="sm" color="gray.900"></Text>
                                <br />
                                <Flex justify="space-between">
                                    <Image src={Irrigation} />
                                    <VStack align="flex-start" spacing={8}>
                                        <VStack align="flex-start">
                                            <HStack spacing={10}>
                                                <Heading as="h4" size="md" color="gray.900">- Ha</Heading>
                                                <Heading as="h6" size="xs" color="gray.900">-</Heading>
                                            </HStack>
                                            <Tooltip label="Either for River, Dam, Lake, Pond" fontSize="md">
                                                <HStack>
                                                    <Text fontSize="sm" color="gray.900">Closeness to Water Body</Text>

                                                    <AiOutlineInfoCircle />

                                                </HStack>
                                            </Tooltip>
                                        </VStack>

                                        <VStack align="flex-start">
                                            <HStack spacing={10}>
                                                <Heading as="h4" size="md" color="gray.900">- Ha</Heading>
                                                <Heading as="h6" size="xs" color="gray.900">-</Heading>
                                            </HStack>
                                            <Text fontSize="sm" color="gray.900">Ground Water Potentia</Text>
                                        </VStack>
                                    </VStack>
                                </Flex>
                            </Box>
                        </GridItem>}

                        <GridItem>
                            <Flex justify="space-between">
                                <Heading as="h6" size="xs" color="gray.900">Vegetation Checks</Heading>

                            </Flex>
                            <br />
                            <VegetationDoughnutChart yes={yes} no={no} inProgress={inProgress} />
                            <br />
                            <Flex justify="space-around">
                                <VStack>
                                    <Heading as="h5" size="sm" color="gray.900">{kFormatter(parseFloat(yes[0]?.area_sum ?? 0))}</Heading>
                                    <Text fontSize="xs">Passed (Ha)</Text>
                                </VStack>
                                <VStack>
                                    <Heading as="h5" size="sm" color="gray.900">{kFormatter(parseFloat(no[0]?.area_sum ?? 0))}</Heading>
                                    <Text fontSize="xs">Failed (Ha)</Text>
                                </VStack>
                                <VStack>
                                    <Heading as="h5" size="sm" color="gray.900">{kFormatter(parseFloat(inProgress[0]?.area_sum ?? 0))}</Heading>
                                    <Text fontSize="xs">In Progress (Ha)</Text>
                                </VStack>
                            </Flex>
                        </GridItem>
                    </Grid>
                </Box>
            </Box>
        </Flex >
    );
}

export default Dashboard;