import React, { useMemo, useContext, useState } from 'react';
import {
    Box,
    HStack,
    Heading,
    Select,
    Flex,
    Text,
    VStack, Spinner, Divider, IconButton,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Center, Image
} from "@chakra-ui/react";
import { useTable, usePagination } from 'react-table'
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { usePartners, useProviders } from '../verification/helpers/fetcher';
import { useReport } from './helpers/fetcher';
import { useStates, useCrops } from '../../utils/data/fetcher';
import { Context } from '../../context/Context';
import ProfileHeader from '../layout/ProfileHeader';
import { renderOverlay, renderVegetationCheck, renderVerificationStatus } from '../verification/helpers/renderData';
import { useTenants } from '../tenants/helpers/fetcher';
import Can from '../../utils/rbac/Can';
import ReportTable from './ReportTable';



function Report() {
    const history = useHistory()
    const { user } = useContext(Context)
    const [selectedTenant, setSelectedTenant] = useState("")
    const [selectedStatus, setSelectedStatus] = useState("")
    const [selectedPartner, setSelectedPartner] = useState("")
    const [selectedProvider, setSelectedProvider] = useState("")
    const [selectedState, setSelectedState] = useState("")
    const [selectedCrop, setSelectedCrop] = useState("")

    const { role } = user

    const filterTenant = selectedTenant ? `&tenant=${selectedTenant}` : '';
    const filterStatus = selectedStatus ? `&overall_status=${selectedStatus}` : '';
    const filterPartner = selectedPartner ? `&partner=${selectedPartner}` : '';
    const filterProvider = selectedProvider ? `&provider=${selectedProvider}` : '';
    const filterState = selectedState ? `&state=${selectedState}` : '';
    const filterCrop = selectedCrop ? `&crop=${selectedCrop}` : '';

    // const tenantState = tenant?.country ? `?country=${tenant.country}` : '';


    const filterStateByProvider = selectedProvider ? `?provider=${selectedProvider}` : '';
    const filterAnchorByStateAndProvider = filterStateByProvider + filterState



    const filterPath = filterTenant + filterState + filterStatus + filterProvider + filterCrop + filterPartner;

    const { data: entity, isLoading } = useReport(`tie/data?per_page=${1000}${filterPath}`, user.token, filterPath)

    const { data: partners } = usePartners(`partners/tie${filterAnchorByStateAndProvider}`, user.token)
    const { data: providers } = useProviders(`providers/tie`, user.token)
    const { states, } = useStates(`tie/states${filterStateByProvider}`, user.token)
    const { crops, } = useCrops("crops/tie", user.token)

    const { data: tenantEntity } = useTenants(`tenants/tie`, user.token)
    const tenants = tenantEntity.tenants ?? []
    const columns = useMemo(
        () => [
            {
                Header: "Reference ID",
                accessor: "reference",

            },
            {
                Header: "ID",
                accessor: "_id",

            },
            {
                Header: "Organizations",
                accessor: "partner"
            },

            {
                Header: "Created",
                accessor: "created",
                Cell: ({ value }) => {
                    return <span>{value ? dayjs(value).format('YYYY/MM/DD') : ''}</span>
                }

            },
            {
                Header: "Status",
                accessor: "overall_status",
                Cell: ({ value }) => renderVegetationCheck(value)
            },
            {
                Header: "Within State / Region",
                accessor: "within_state",
                Cell: ({ value }) => renderVerificationStatus(value)
            },
            {
                Header: "Within LGA / District",
                accessor: "within_lga",
                Cell: ({ value }) => renderVerificationStatus(value)
            },
            {
                Header: "Within Country",
                accessor: "within_country",
                Cell: ({ value }) => renderVerificationStatus(value)
            },
            {
                Header: "Cluster Name",
                accessor: "cluster_name"
            },
            {
                Header: "Overlay",
                accessor: "overlay",
                Cell: ({ value }) => renderOverlay(value)
            },
            {
                Header: "Overlay ID",
                accessor: "overlay_ids",
            },
            {
                Header: "Reason",
                accessor: "reject_reason",

            },
            {
                Header: "Crop",
                accessor: "crop"
            },
            {
                Header: "State / Region",
                accessor: "state"
            },
            {
                Header: "LGA / District",
                accessor: "lga"
            },
            {
                Header: "Confirmed Size",
                accessor: "returned_size"
            },
        ], []
    )

    const data = useMemo(() => entity.aaData || [], [entity.aaData])

    const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 }, }, usePagination)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,

        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
    } = tableInstance

    return (
        <Flex>
            {/* {showDrawer && <Drawer />} */}
            <Box px={5} py={7} bg="brand.background" w="100%" minHeight="100vh" >
                <Flex justify="space-between">
                    <HStack spacing={4}>
                        <IconButton
                            onClick={() => history.goBack()}
                            aria-label="Go Back"
                            icon={<FiChevronLeft />}
                        />
                        <Heading as="h4" size="md">Report</Heading>
                    </HStack>

                    <ProfileHeader />
                </Flex>
                <Box bg="rgb(255,255,255,0.55)" p={5} rounded="lg" mt={5}>
                    <HStack spacing={5}>
                        <Can
                            role={role}
                            perform="tenants:visit"
                            yes={() => (
                                <Select
                                    placeholder="Tenants"
                                    onChange={(e) => {
                                        setSelectedTenant(e.target.value)
                                    }}

                                    borderColor="brand.green"

                                >
                                    <option value="">All</option>
                                    {
                                        tenants.map(item => (
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        ))
                                    }
                                </Select>

                            )}
                            no={() => null}
                        />
                        <Select
                            borderColor="brand.green"
                            placeholder="Sources"
                            onChange={(e) => {
                                setSelectedProvider(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            {
                                providers.map((item, i) => (
                                    <option key={i} value={item.provider}>{item.provider}</option>
                                ))
                            }
                        </Select>
                        <Select
                            borderColor="brand.green"
                            placeholder="State / Region"
                            onChange={(e) => {
                                setSelectedState(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            {states.map(state => (
                                <option key={state.state} value={state.state}>{state.state}</option>
                            ))}
                        </Select>
                        <Select
                            borderColor="brand.green"
                            placeholder="Organizations"
                            onChange={(e) => {
                                setSelectedPartner(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            {
                                partners.map((item, i) => (
                                    <option key={i} value={item.partner}>{item.partner}</option>
                                ))
                            }
                        </Select>
                        <Select
                            borderColor="brand.green"
                            placeholder="Result"
                            onChange={(e) => {
                                setSelectedStatus(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            <option value="PASS">PASS</option>
                            <option value="FAIL">FAIL</option>
                            <option value="IN PROGRESS">IN PROGRESS</option>
                        </Select>
                        <Select
                            borderColor="brand.green"
                            placeholder="Crop"
                            onChange={(e) => {
                                setSelectedCrop(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            {crops.map((item, i) => (
                                <option key={i} value={item.crop}>{item.crop}</option>
                            ))}
                        </Select>
                    </HStack>
                    <br />
                    <Box mt={5} overflow="auto" rounded="md" bg="white">
                        <table {...getTableProps()}>
                            <thead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (

                                                    <th {...column.getHeaderProps()}>
                                                        {
                                                            column.render('Header')}
                                                    </th>
                                                ))}
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {// Loop over the table rows
                                    page.map(row => {
                                        // Prepare the row for display
                                        prepareRow(row)
                                        return (
                                            // Apply the row props
                                            <tr {...row.getRowProps()}>
                                                {// Loop over the rows cells
                                                    row.cells.map(cell => {
                                                        // Apply the cell props
                                                        return (
                                                            <td {...cell.getCellProps()}>
                                                                {// Render the cell contents
                                                                    cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}

                            </tbody>
                        </table>
                        {!filterPath &&
                            <Box py="5"><Text textAlign="center">Select filter to populate report</Text></Box>
                        }
                        {isLoading && filterPath !== "" &&
                            <VStack mt={4}>
                                <Spinner color="brand.green" />
                            </VStack>}
                        {!isLoading && entity.aaData?.length === 0 && <Text textAlign="center">No records found</Text>}
                    </Box>
                    <Flex justify="flex-end" mt={4}>
                        <HStack spacing={2}>
                            {/* <Text fontSize="sm">Total Records:</Text>
                            <Text fontSize="sm">{entity?.iTotalRecords ?? '-'}</Text> */}
                            <Divider orientation="vertical" />
                            <Select
                                onChange={(e) => setPageSize(e.target.value)}
                                placeholder="Select option"
                                defaultValue={"10"} w={"30%"}>
                                <option value="10">10 / page</option>
                                <option value="20">20 / page</option>
                                <option value="50">50 / page</option>
                                <option value="100">100 / page</option>
                            </Select>
                            <Divider orientation="vertical" />
                            <IconButton variant="ghost" onClick={() => previousPage()} disabled={!canPreviousPage} aria-label="Previous Page" icon={<FiChevronLeft />} size="xs" color="brand.primary" />
                            <Text fontSize="sm"> Page {pageIndex + 1} of {pageOptions.length}</Text>
                            <IconButton variant="ghost" onClick={() => nextPage()} disabled={!canNextPage} aria-label="Previous Page" icon={<FiChevronRight />} size="xs" color="brand.primary" />

                            <Text fontSize="sm">Go to Page</Text>
                            <NumberInput
                                onChange={(value) => {
                                    const page = value ? value - 1 : 0
                                    gotoPage(page)
                                }}
                                min={0}
                                size="sm" w={20}
                                defaultValue={pageIndex + 1}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </HStack>
                    </Flex>
                    <br />

                    {isLoading && filterPath !== "" &&
                        <VStack mt={4}>
                            <Spinner color="brand.green" />
                        </VStack>}
                    {!isLoading &&
                        <>
                            {data.map(item => (
                                <Box key={item._id} w="100%" my={8} bg="white" p={5}>
                                    <Box w="100%">
                                        <Center>
                                            <Image src={item.map_image} />
                                        </Center>
                                    </Box>
                                    <br />
                                    <br />
                                    <ReportTable item={item} />
                                </Box>
                            ))}
                        </>
                    }
                </Box>
            </Box>

        </Flex >

    )
}

export default Report;