import React, { useContext, useState, useMemo, useRef } from 'react';
import {
    Box,
    Button,
    Flex,
    Text,
    Heading,
    Stack,
    Input,
    InputLeftElement,
    InputGroup,
    Icon,
    HStack,
    VStack,
    IconButton,
    Divider,
    Select,
    Spinner,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from "@chakra-ui/react";
import axios from 'axios';
import dayjs from 'dayjs';
import { useTable } from 'react-table'
import { AiOutlineEdit, AiOutlineSearch } from 'react-icons/ai'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useHistory } from 'react-router-dom';
import { RiDeleteBin6Line } from 'react-icons/ri'
import Drawer from '../layout/Drawer';
import { Context } from '../../context/Context';
import { useUsers } from './helpers/fetcher';
import Can from '../../utils/rbac/Can';
import ProfileHeader from '../layout/ProfileHeader'
import config from '../../config'
import { showToast } from '../../utils/toast';
import { mutate } from 'swr';


function Users() {
    const history = useHistory()
    const { user } = useContext(Context)
    const [isOpen, setIsOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)

    const cancelRef = useRef()
    const { role } = user


    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    const isSuperAdmin = role === 'SuperAdmin'

    const [pageIndex, setPageIndex] = useState(0)
    const [limit, setLimit] = useState(10)


    const filterPage = pageIndex > 0 ? `&page=${pageIndex}` : '';

    const filterPath = filterPage

    const { data: entity, isLoading } = useUsers(`users/tie?per_page=${limit}${filterPath}`, user.token)

    const onClose = () => setIsOpen(false)

    const superAdminColumns = useMemo(
        () => [
            {
                Header: "First Name",
                accessor: "first_name",

            },
            {
                Header: "Last Name",
                accessor: "last_name"
            },
            {
                Header: "Email",
                accessor: "email"
            },
            {
                Header: "Group",
                accessor: "role",
            },
            {
                Header: "Tenant",
                accessor: "tenant.name",
            },
            {
                Header: "Last Login",
                accessor: "last_login",
                Cell: ({ value }) => {
                    return <span>{value ? dayjs(value).format('YYYY/MM/DD hh:mm') : ''}</span>
                }

            },
            {
                Header: "Edit",
                accessor: "_id",
                Cell: ({ value, row }) => {
                    const { original } = row
                    return (
                        <Button variant="ghost" onClick={() => history.push(`/users/edit/${value}`, original)}>
                            <AiOutlineEdit style={{ fontSize: 18 }} />
                        </Button>
                    )

                }

            },
            {
                Header: "Delete",
                Cell: ({ row }) => {
                    const { original } = row;
                    return (
                        <Button variant="ghost" onClick={() => {
                            setSelectedUser(original._id)
                            setIsOpen(true)
                        }}>
                            <RiDeleteBin6Line style={{ fontSize: 18 }} />
                        </Button>
                    )
                }

            },
        ], [history]
    )


    const columns = useMemo(
        () => [
            {
                Header: "First Name",
                accessor: "first_name",

            },
            {
                Header: "Last Name",
                accessor: "last_name"
            },
            {
                Header: "Email",
                accessor: "email"
            },
            {
                Header: "Group",
                accessor: "role",
            },

            {
                Header: "Last Login",
                accessor: "last_login",
                Cell: ({ value }) => {
                    return <span>{value ? dayjs(value).format('YYYY/MM/DD hh:mm') : ''}</span>
                }

            },
            {
                Header: "Edit",
                accessor: "_id",
                Cell: ({ value, row }) => {
                    const { original } = row
                    return (
                        <Button variant="ghost" onClick={() => history.push(`/users/edit/${value}`, original)}>
                            <AiOutlineEdit style={{ fontSize: 18 }} />
                        </Button>
                    )

                }

            },
            {
                Header: "Delete",
                Cell: ({ row }) => {
                    const { original } = row
                    return (
                        <Button variant="ghost" onClick={() => {
                            setSelectedUser(original._id)
                            setIsOpen(true)
                        }}>
                            <RiDeleteBin6Line style={{ fontSize: 18 }} />
                        </Button>
                    )
                }

            },
        ], [history]
    );
    const data = useMemo(() => entity.users || [], [entity])

    const columnsToShow = isSuperAdmin ? superAdminColumns : columns

    const tableInstance = useTable({ columns: columnsToShow, data })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    const setPrev = () => {
        setPageIndex(pageIndex - 1)
    }

    const setNext = () => {
        setPageIndex(pageIndex + 1)
    }

    const deleteUser = async () => {
        if (!selectedUser) {
            setIsOpen(false)
            showToast("error", "An error occured when deleting user")
            return
        }
        setIsDeleting(true)
        try {
            const res = await axios.delete(`${config.baseUrl}/account/${selectedUser}/tie`, requestOptions)

            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }

            else {
                showToast("success", "Successfully deleted user")
                mutate([`${config.baseUrl}/users/tie?per_page=${limit}${filterPath}`, user.token])
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            setIsDeleting(false)
            setIsOpen(false)
        }
    }


    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">User Management</Heading>
                    <ProfileHeader />
                </Flex>

                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Stack spacing={4}>
                        <Heading as="h5" size="sm">All Users</Heading>
                        <Flex justify="space-between">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<Icon as={AiOutlineSearch} color="gray.500" />}
                                />
                                <Input type="phone" placeholder="Search" w={60} />
                            </InputGroup>
                            <Can
                                role={role}
                                perform="user:create"
                                yes={() => (
                                    <Stack spacing={4} direction="row" align="center">
                                        <Button onClick={() => history.push('/users/add')} colorScheme="teal" size="md">
                                            <Text fontWeight="normal">Create User</Text>
                                        </Button>
                                        <Button colorScheme="teal" size="md">
                                            <Text fontWeight="normal">Upload Users</Text>
                                        </Button>
                                    </Stack>

                                )}
                                no={() => null}
                            />

                        </Flex>
                    </Stack>
                    <Box mt={4} overflow="auto">
                        <table {...getTableProps()}>
                            <thead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (

                                                    <th {...column.getHeaderProps()}>
                                                        {
                                                            column.render('Header')}
                                                    </th>
                                                ))}
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {// Loop over the table rows
                                    rows.map(row => {
                                        // Prepare the row for display
                                        prepareRow(row)
                                        return (
                                            // Apply the row props
                                            <tr {...row.getRowProps()}>
                                                {// Loop over the rows cells
                                                    row.cells.map(cell => {
                                                        // Apply the cell props
                                                        return (
                                                            <td {...cell.getCellProps()}>
                                                                {// Render the cell contents
                                                                    cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}

                            </tbody>
                        </table>
                        {isLoading &&
                            <VStack mt={4}>
                                <Spinner color="brand.green" />
                            </VStack>}
                        {!isLoading && entity.users.length === 0 && <h6>No records found</h6>}
                    </Box>

                    <Flex justify="flex-end" mt={4}>
                        <HStack spacing={2}>
                            <Text fontSize="sm">Total Records:</Text>
                            <Text fontSize="sm">{entity?.iTotalRecords ?? '-'}</Text>
                            <Divider orientation="vertical" />
                            <Select
                                onChange={(e) => setLimit(e.target.value)}
                                placeholder="Select option"
                                defaultValue={"10"} w={"20%"}>
                                <option value="10">10 / page</option>
                                <option value="20">20 / page</option>
                                <option value="50">50 / page</option>
                                <option value="100">100 / page</option>
                            </Select>
                            <Divider orientation="vertical" />
                            <IconButton variant="ghost" disabled={pageIndex <= 0} onClick={setPrev} aria-label="Previous Page" icon={<FiChevronLeft />} size="xs" color="brand.primary" />
                            <Text fontSize="sm"> Page {pageIndex + 1} of {entity ? entity.total_pages : '-'}</Text>
                            <IconButton variant="ghost" disabled={pageIndex >= entity.total_pages} onClick={setNext} aria-label="Previous Page" icon={<FiChevronRight />} size="xs" color="brand.primary" />

                            <Text fontSize="sm">Go to Page</Text>
                            <NumberInput
                                onChange={(page) => {
                                    if (page > entity.total_pages || page < 1) {
                                        return
                                    }
                                    setPageIndex(page - 1)
                                }}
                                min={0}
                                size="sm" w={20}
                                defaultValue={pageIndex + 1}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>

                        </HStack>
                    </Flex>
                </Box>
                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Delete User
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                Are you sure? You can't undo this action afterwards.
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button isLoading={isDeleting} colorScheme="red" onClick={deleteUser} ml={3}>
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Box>

        </Flex>

    )
}

export default Users;