import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Heading,
    Grid,
    Stack, Text, Divider, SimpleGrid, BreadcrumbItem, BreadcrumbLink, Breadcrumb, Button,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton
} from "@chakra-ui/react";
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import config from '../../config';
import { showToast } from '../../utils/toast';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import VerificationMap from '../../utils/maps/VerificationMap';
import { renderVegetationCheck, renderVerificationStatus, renderCoordinates, renderAlternateVegetationCheck } from './helpers/renderData';
import { getPoints } from './helpers/getPoints';


function VerificationDetails() {

    const { state } = useLocation()
    const points = getPoints(state.coord)
    const [warnings, setWarnings] = useState([])


    useEffect(() => {
        const getEarlyWarning = async () => {

            const data = {
                unique_id: state.reference
            }
            try {
                const res = await axios.post(`${config.baseUrl}/tie/early-warning`, data)
                setWarnings(res.data.entity)
            }
            catch (e) {
                showToast("error", e.message)
            }
        }
        getEarlyWarning();
    }, [state]);

    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/verification">
                                <Heading as="h4" size="md">Verification ID</Heading>
                            </Link>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink href="#">{state._id}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Stack spacing={5}>
                        <Heading as="h5" size="sm" >Unique ID: {state._id}</Heading>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <Box w="100%" pb={8}>
                                <VerificationMap points={points} />
                                <br />
                                <Link
                                    to={{
                                        pathname: "/dashboard/farm-analysis",
                                        state
                                    }}
                                >
                                    <Button colorScheme="teal" size="md">Analyze Farm</Button>
                                </Link>
                            </Box>
                            <Box w="100%" pb={8}>
                                <Stack spacing={4}>
                                    <Heading as="h5" size="sm">
                                        User Provided Info
                                    </Heading>
                                    <SimpleGrid columns={2} spacingY="10px">
                                        <Text fontSize="sm">Provider:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.provider}
                                        </Heading>
                                        <Text fontSize="sm">Anchor:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.partner}
                                        </Heading>
                                        <Text fontSize="md">Crop:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.crop}
                                        </Heading>
                                        <Text fontSize="md">Coordinates:</Text>
                                        <Heading as="h6" size="xs">
                                            {renderCoordinates(state.coord)}
                                        </Heading>
                                        <Text fontSize="md">Country:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.country}
                                        </Heading>
                                        <Text fontSize="md">LGA / District:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.lga}
                                        </Heading>
                                        <Text fontSize="md">State / Region:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.state}
                                        </Heading>
                                    </SimpleGrid>
                                </Stack>
                            </Box>
                        </Grid>
                        <Box>
                            {warnings.map(warning => {
                                return (
                                    <Alert key={warning.id} status='warning' mb={2} rounded="md">
                                        <AlertIcon />
                                        <Box flex='1'>
                                            <AlertTitle mr={4}>PESTICIDE</AlertTitle>
                                            <AlertDescription display='block'>
                                                Your application has been received. We will review your application and
                                                respond within the next 48 hours.
                                            </AlertDescription>
                                        </Box>

                                        {/* <AlertDescription display="block">Apply Pesticide</AlertDescription> */}
                                        {/* <CloseButton position='absolute' right='8px' top='8px' /> */}
                                    </Alert>
                                )
                            })}
                        </Box>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <Box w="100%" p={4}>
                                <Stack spacing={4}>
                                    <Heading as="h5" size="sm">
                                        Actions
                                    </Heading>
                                    <SimpleGrid columns={2} spacingY="10px">
                                        <Text fontSize="md">Overlay Check:</Text>
                                        <Box>
                                            {renderVegetationCheck(state.overlay)}
                                        </Box>
                                        <Text fontSize="md">Vegetation Check:</Text>
                                        <Box>
                                            {renderAlternateVegetationCheck(state.status)}
                                        </Box>
                                        <Text fontSize="md">Within Country:</Text>
                                        <Box>
                                            {renderVerificationStatus(state.within_country)}
                                        </Box>
                                        <Text fontSize="md">Within State / Region:</Text>
                                        <Box>
                                            {renderVerificationStatus(state.within_state)}
                                        </Box>
                                        <Text fontSize="md">Within LGA / District:</Text>
                                        <Box>
                                            {renderVerificationStatus(state.within_lga)}
                                        </Box>
                                    </SimpleGrid>
                                </Stack>
                            </Box>
                            <Box w="100%" p={4}>
                                <Stack spacing={4}>
                                    <Heading as="h5" size="sm">
                                        Returned Information
                                    </Heading>
                                    <SimpleGrid columns={2} spacingY="10px">
                                        <Text fontSize="md">Country:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.returned_country}
                                        </Heading>
                                        <Text fontSize="md">LGA / District:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.returned_lga}
                                        </Heading>
                                        <Text fontSize="md">State / Region:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.returned_state}
                                        </Heading>
                                    </SimpleGrid>
                                </Stack>
                            </Box>
                        </Grid>
                    </Stack>

                </Box>
            </Box>
        </Flex >
    )
}

export default VerificationDetails;