import { useRef, useState, useEffect } from 'react';
import * as plotty from 'plotty';
import {
    OverlayView,
    GoogleMap
} from '@react-google-maps/api';

const GeoTIFF = require('geotiff')

const GeoTIFFOverlay = (props) => {
    const inputEl = useRef(null);
    const [bounds, setBounds] = useState(new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(0, 0),
        new window.google.maps.LatLng(0, 0)
    ))

    useEffect(() => {
        const renderImage = async () => {

            try {
                const tiff = await GeoTIFF.fromUrl(props.url)
                const image = await tiff.getImage();

                const bbox = image.getBoundingBox()
                const boundsData = new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(bbox[1], bbox[0]),
                    new window.google.maps.LatLng(bbox[3], bbox[2])
                )
                setBounds(boundsData)
                const data = await image.readRasters();
                // const [red, green, blue] = await image.readRasters();

                const canvas = document.getElementById("plot");
                const plot = new plotty.plot({
                    canvas,
                    data: data[0],
                    width: image.getWidth(),
                    height: image.getHeight(),
                    domain: [-1, 1],
                    colorScale: 'viridis'

                });
                plot.render();
            }
            catch (e) {
                console.log(e.message)
            }

        }
        renderImage()
    }, [props]);

    return (
        <GoogleMap
            mapTypeId={window.google.maps.MapTypeId.HYBRID}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={bounds.getCenter()}
            // center={{ lat: 11.28003299, lng: 10.19680425 }}
            zoom={13}
        >
            {/* <OverlayView
                bounds={bounds}
                mapPaneName={OverlayView.OVERLAY_LAYER}
            >
                <div style={{ position: 'absolute', height: '100%', width: '100%' }} >
                    <canvas id="plot" style={{ position: 'absolute', height: '100%', width: '100%', opacity: .6 }} ref={inputEl} />
                </div>
            </OverlayView> */}
        </GoogleMap>
    );
}

export default GeoTIFFOverlay;
