import React, { StrictMode } from 'react';
import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
} from '@chakra-ui/react';
import "focus-visible/dist/focus-visible"
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import ContextProvider from './context/Context';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file



const colors = {
  brand: {
    green: "#14B6A7",
    800: "#153e75",
    700: "#2a69ac",
    background: "#DFE7E8",
    primary: "#009688",
    lightGreen: "rgba(20, 182, 167, 0.1)"
  },
  lightGrey: "#F2F3F4",
  text: "#6C757D"
}

const fonts = {
  body: `"Nunito", sans-serif`,
  heading: `"Nunito", sans-serif`,
}

const theme = extendTheme({ colors, fonts })





ReactDOM.render(
  <StrictMode>
    <ColorModeScript />
    <ContextProvider>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </ContextProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
