import React, { useContext, useState, useMemo } from 'react';
import {
    Box,
    Button,
    Flex,
    Text,
    Heading,
    Stack,
    Input,
    InputLeftElement,
    InputGroup,
    Icon,
    HStack,
    VStack,
    IconButton,
    Divider,
    Select,
    Spinner,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from "@chakra-ui/react";
import { useTable } from 'react-table'
import { AiOutlineSearch } from 'react-icons/ai'
import { FiChevronLeft, FiChevronRight, FiEye } from 'react-icons/fi'
import { useHistory } from 'react-router-dom';

import Drawer from '../layout/Drawer';
import { Context } from '../../context/Context';
import { useVerification } from '../verification/helpers/fetcher';
import { renderSettlementCheck } from '../verification/helpers/renderData';
import ProfileHeader from '../layout/ProfileHeader'


function SetlementCheckList() {
    const history = useHistory()
    const { user } = useContext(Context)
    const [pageIndex, setPageIndex] = useState(0)
    const [limit, setLimit] = useState(10)

    const filterPage = pageIndex > 0 ? `&page=${pageIndex}` : '';

    const filterPath = filterPage

    const { data: entity, isLoading } = useVerification(`tie/data?per_page=${limit}&is_review=0&machine_status=YES${filterPath}`, user.token)


    const columns = useMemo(
        () => [
            {
                Header: "Unique ID",
                accessor: "_id",

            },
            {
                Header: "Cluster Name",
                accessor: "cluster_name",

            },
            {
                Header: "Anchor",
                accessor: "partner",

            },
            {
                Header: "Status",
                accessor: "machine_status",
                Cell: ({ value }) => renderSettlementCheck(value)

            },
            {
                Header: "Action",
                Cell: ({ row }) => {
                    const { original } = row;
                    return (
                        <Button variant="ghost" onClick={() => history.push(`/settlement-check/${original._id}`, original)}>
                            <FiEye style={{ fontSize: 18 }} />
                        </Button>
                    )
                }

            },
        ], [history]
    )
    const data = useMemo(() => entity.aaData || [], [entity])

    const tableInstance = useTable({ columns, data })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    const setPrev = () => {
        setPageIndex(pageIndex - 1)
    }

    const setNext = () => {
        setPageIndex(pageIndex + 1)
    }


    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Settlement Check</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Stack spacing={4}>
                        <Heading as="h5" size="sm">Settlement Check</Heading>
                        <Flex justify="space-between">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<Icon as={AiOutlineSearch} color="gray.500" />}
                                />
                                <Input type="phone" placeholder="Search" w={60} />
                            </InputGroup>
                        </Flex>
                    </Stack>
                    <Box mt={4} overflow="auto">
                        <table {...getTableProps()}>
                            <thead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (

                                                    <th {...column.getHeaderProps()}>
                                                        {
                                                            column.render('Header')}
                                                    </th>
                                                ))}
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {// Loop over the table rows
                                    rows.map(row => {
                                        // Prepare the row for display
                                        prepareRow(row)
                                        return (
                                            // Apply the row props
                                            <tr {...row.getRowProps()}>
                                                {// Loop over the rows cells
                                                    row.cells.map(cell => {
                                                        // Apply the cell props
                                                        return (
                                                            <td {...cell.getCellProps()}>
                                                                {// Render the cell contents
                                                                    cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}

                            </tbody>
                        </table>
                        {isLoading &&
                            <VStack mt={4}>
                                <Spinner color="brand.green" />
                            </VStack>}
                        {!isLoading && entity.aaData?.length === 0 && <Text textAlign="center">No records found</Text>}
                    </Box>

                    <Flex justify="flex-end" mt={4}>
                        <HStack spacing={2}>
                            <Text fontSize="sm">Total Records:</Text>
                            <Text fontSize="sm">{entity?.iTotalRecords ?? '-'}</Text>
                            <Divider orientation="vertical" />
                            <Select
                                onChange={(e) => setLimit(e.target.value)}
                                placeholder="Select option"
                                defaultValue={"10"} w={"20%"}>
                                <option value="10">10 / page</option>
                                <option value="20">20 / page</option>
                                <option value="50">50 / page</option>
                                <option value="100">100 / page</option>
                            </Select>
                            <Divider orientation="vertical" />
                            <IconButton variant="ghost" disabled={pageIndex <= 0} onClick={setPrev} aria-label="Previous Page" icon={<FiChevronLeft />} size="xs" color="brand.primary" />
                            <Text fontSize="sm"> Page {pageIndex + 1} of {entity ? entity.total_pages : '-'}</Text>
                            <IconButton variant="ghost" disabled={pageIndex >= entity.total_pages} onClick={setNext} aria-label="Previous Page" icon={<FiChevronRight />} size="xs" color="brand.primary" />

                            <Text fontSize="sm">Go to Page</Text>
                            <NumberInput
                                onChange={(page) => {
                                    if (page > entity.total_pages || page < 1) {
                                        return
                                    }
                                    setPageIndex(page - 1)
                                }}
                                min={0}
                                size="sm" w={20}
                                defaultValue={pageIndex + 1}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>

                        </HStack>
                    </Flex>
                </Box>
            </Box>
        </Flex >

    )
}

export default SetlementCheckList;