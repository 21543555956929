import React, { useEffect } from 'react';
import * as plotty from 'plotty';
import { useLocation } from 'react-router-dom';
import { fromUrl } from 'geotiff';


const TiffView = () => {

    const { state } = useLocation()

    useEffect(() => {
        const renderImage = async () => {
            try {
                const tiff = await fromUrl(state.image);
                const image = await tiff.getImage();
                const data = await image.readRasters();
                const canvas = document.getElementById("plot");
                const plot = new plotty.plot({
                    canvas,
                    data: data[0],
                    width: image.getWidth(),
                    height: image.getHeight(),
                    domain: [-128, 256],
                    colorScale: "earth"
                });
                plot.render();
            }
            catch (e) {
                console.log(e.message)
            }

        }
        renderImage()
    }, [state]);
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <canvas id="plot"></canvas>
        </div>
    );
}

export default TiffView;
