import * as Yup from 'yup';


export const editUserValidationSchema = Yup.object({
    email: Yup.string().email("The email address you entered is invalid").required("Email is required"),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    mobile: Yup.string().notRequired(),
})

export const changePasswordValidationSchema = Yup.object({
    old_password: Yup.string().required("Old Password is required"),
    password: Yup.string().required("New Password is required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords don't match!")
        .required('Required')
})

