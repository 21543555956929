import React, { useState } from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import config from '../../config';


// const options = {
//     fillColor: "lightblue",
//     fillOpacity: 1,
//     strokeColor: "red",
//     strokeOpacity: 1,
//     strokeWeight: 2,
//     clickable: true,
//     draggable: false,
//     editable: false,
//     geodesic: false,
//     zIndex: 1
// }



function VerificationMap(props) {

    const [center] = useState({ lat: 9.0820, lng: 8.6753 })
    return (
        <LoadScript
            googleMapsApiKey={config.mapsKey}
        >
            <GoogleMap
                mapTypeId="satellite"
                center={props.points?.length ? { lat: parseFloat(props.points[0].lat), lng: parseFloat(props.points[0].lng) } : center}
                mapContainerStyle={{ height: props.height ? props.height : '60vh' }}
                zoom={13}
            >
                <Polygon
                    path={props.points}
                    //key={1}
                    options={{
                        fillColor: "#009688",
                        fillOpacity: 0.4,
                        strokeColor: "#000",
                        strokeOpacity: 1,
                        strokeWeight: 1
                    }} />

                {/* <>
                    {lat && lng && item &&
                        <InfoWindow
                            onCloseClick={() => {
                                return
                            }}
                            position={{ lat, lng }}
                        >
                            <div>
                                <h3>
                                    CBN Applicant ID
                            </h3>
                                <h1>{item.farmer.cbn_applicant_id}</h1>
                            </div>
                        </InfoWindow>
                    }

                    {props.data.data.filter(v => v.points !== null).map((item, i) => {
                        const formattedPoints = item.points.map(point => {
                            return { lat: parseFloat(point.lat), lng: parseFloat(point.lng) }
                        })
                        return (
                            <Polygon
                                key={i}
                                onClick={() => {
                                    setCenter({ lat: parseFloat(item.points[1].lat), lng: parseFloat(item.points[1].lng) })
                                    setLat(parseFloat(item.points[1].lat))
                                    setLng(parseFloat(item.points[1].lng))
                                    setItem(item)
                                }}
                                paths={formattedPoints}
                                options={options} />
                        )
                    })}
                </> */}
            </GoogleMap>
        </LoadScript>
    )
}

export default VerificationMap;




// AIzaSyAE5beByDMsRgJwMDLutqutRI_ZjxnyCJs