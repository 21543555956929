import React from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, Heading, Textarea, FormErrorMessage } from '@chakra-ui/react';

function ChakraTextArea(props) {
    const { label, name, required, ...rest } = props;

    return (
        <Field name={name}>
            {(props) => {
                const { field, form } = props
                return (
                    <FormControl
                        isInvalid={form.errors[name] && form.touched[name]}
                        isRequired={required ? required : false}
                    >
                        <FormLabel htmlFor={name} mb={2}>
                            <Heading as="a" fontSize="sm">{label}</Heading>
                        </FormLabel>
                        <Textarea
                            {...field}
                            {...rest}
                            id={name}
                            border="0"
                            bg="lightGrey"
                            focusBorderColor="brand.green" />
                        <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default ChakraTextArea;


