import * as Yup from 'yup';


export const quickVerificationSchema = Yup.object({
    crop: Yup.string().required("Crop is required"),
    source: Yup.string().required("Source is required"),
    group: Yup.string().required("Group is required"),
    date: Yup.string().notRequired(),
    size: Yup.string().required("Size is required"),
    cluster_name: Yup.string().required("Cluster Name is required"),
    state: Yup.string().required("State is required"),
    lga: Yup.string().required("LGA is required"),
})




