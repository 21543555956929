import React, { useContext } from 'react';
import { Box, Grid, Stack, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { pickBy, identity } from 'lodash';
import axios from 'axios';
import { Context } from '../../context/Context';
import { showToast } from '../../utils/toast';
import FormikControl from '../../utils/form/FormikControl';
import { editUserValidationSchema } from './helpers/validationschemas'
import config from '../../config'



function EditProfile() {
    const { user, dispatchUser } = useContext(Context);

    const initialValues = {
        first_name: user?.first_name ?? '',
        last_name: user?.last_name ?? '',
        mobile: user?.mobile ?? '',
        email: user?.email ?? '',
    }

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    async function onSubmit(values, onSubmitProps) {

        const body = pickBy(values, identity)
        try {
            const res = await axios.put(`${config.baseUrl}/account/tie`, body, requestOptions)

            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }

            else {
                dispatchUser({
                    type: 'SET_USER', user: res.data.entity
                });

                showToast("success", "Successfully edited profile")
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={editUserValidationSchema}
        >
            {formik => (
                <Form>
                    <Stack spacing={8}>
                        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                            <Box w="100%">
                                <FormikControl
                                    control="input"
                                    type="text"
                                    label="First Name"
                                    name="first_name"
                                    placeholder="First Name"
                                />
                            </Box>
                            <Box w="100%">
                                <FormikControl
                                    control="input"
                                    type="text"
                                    label="Last Name"
                                    name="last_name"
                                    placeholder="Last Name"
                                />
                            </Box>
                        </Grid>

                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <Box w="100%">
                                <FormikControl
                                    control="input"
                                    type="email"
                                    label="Email"
                                    name="email"
                                    placeholder="Email"
                                />
                            </Box>
                            <Box w="100%">
                                <FormikControl
                                    control="input"
                                    type="text"
                                    label="Phone Number"
                                    name="mobile"
                                    placeholder="Phone Number"
                                    required={false}
                                />
                            </Box>
                        </Grid>
                    </Stack>
                    <Box mt={8}>
                        <Button
                            disabled={formik.isSubmitting}
                            isLoading={formik.isSubmitting}
                            loadingText="Submitting"
                            type="submit"
                            bg="brand.primary"
                            color="white"
                            _hover={{ bg: 'brand.green' }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}

export default EditProfile;