import React, { useContext, useState, useRef } from 'react';
import {
    Box,
    Flex,
    Heading,
    Stack,
    Button,
    Image, BreadcrumbItem, BreadcrumbLink, Breadcrumb, Center, Text, Spinner, HStack
} from "@chakra-ui/react";
import { useHistory, useLocation, Link } from 'react-router-dom';
import { pickBy, identity } from 'lodash';
import axios from 'axios';
import { Formik, Form } from 'formik';
import Drawer from '../layout/Drawer';
import { editTenantValidationSchema } from './helpers/validationSchemas';
import FormikControl from '../../utils/form/FormikControl';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';
import config from '../../config'
import { showToast } from '../../utils/toast';
import { useCountries } from '../countries/helpers/fetcher';
import { useCurrencies } from '../currencies/helpers/fetcher';


function EditTenant() {
    const history = useHistory()
    const location = useLocation()
    const { user } = useContext(Context)
    const [uploading, setUploading] = useState(false)
    const [progress, setProgress] = useState(0)
    const fileInput = useRef(null);

    const { state } = location
    const [logo, setLogo] = useState(state.logo)

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
        onUploadProgress: progressEvent => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setProgress(percentCompleted)
        }
    }

    const { data: countries, isLoading: loadingCountries } = useCountries(`countries`, user.token)
    const { data: currencies, isLoading: loadingCurrencies } = useCurrencies(`currencies`, user.token)


    const initialValues = {
        name: state?.name ?? "",
        description: state?.description ?? "",
        sms_sender_id: state?.sms_sender_id ?? "",
        custom_domain: state?.custom_domain ?? "",
        country: state?.country ?? "",
        currency: state?.currency ?? "",
    }

    async function onSubmit(values, onSubmitProps) {
        const body = pickBy(values, identity)
        try {
            const res = await axios.put(`${config.baseUrl}/tenant/${state._id}/tie`, body, requestOptions)
            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }

            else {
                showToast("success", "Successfully edited tenant")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }

    const handleUpload = async (image) => {
        setProgress(0)
        const body = {
            image
        }
        try {
            const res = await axios.put(`${config.baseUrl}/tenant/${state._id}/logo/tie`, body, requestOptions)
            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }

            else {
                setLogo(res.data.entity.logo)
                showToast("success", "Successfully uploaded image")
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            setUploading(false)
        }
    }



    const handleImagePick = (event) => {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
            setUploading(true)
            handleUpload(reader.result)
        };
    }


    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/tenants">
                                <Heading as="h4" size="md">Tenants</Heading>
                            </Link>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink href="#">Edit Tenant</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5} >
                    <Heading as="h5" size="sm">Edit Tenant</Heading>
                    <br />
                    <Stack spacing={10} direction="row">
                        <Box w="50%"
                            mt={4}
                            h="200px"
                            borderColor="gray.200">
                            <Box
                                onClick={() => {
                                    fileInput.current.value = null
                                    fileInput.current.click()
                                }}
                                cursor="pointer"
                            >
                                {logo ?
                                    <Center h="200px" border="1px" borderColor="gray.200">
                                        <Image src={logo} alt="tenant logo" objectFit="cover" maxW="150px" />
                                    </Center>
                                    :

                                    <Center h="200px" border="1px" borderColor="gray.200">
                                        <Text>Click to Upload</Text>
                                    </Center>
                                }
                            </Box>
                            {uploading && <HStack mt={4} spacing={4}>
                                <Spinner />
                                <Text>{progress}%</Text>
                            </HStack>}

                        </Box>
                        <input
                            style={{ display: 'none' }}
                            ref={fileInput}
                            type="file"
                            accept="image/*"
                            onChange={handleImagePick} />
                        <Box w="100%">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                validationSchema={editTenantValidationSchema}
                            >
                                {formik => (
                                    <Form>
                                        <Stack spacing={8}>
                                            <Box w="70%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Name"
                                                    name="name"
                                                    placeholder="Name"
                                                />
                                            </Box>
                                            <Box w="70%">
                                                <FormikControl
                                                    control="textarea"
                                                    type="text"
                                                    label="Description"
                                                    name="description"
                                                    placeholder="Description"
                                                />
                                            </Box>
                                            <Box w="70%">
                                                <FormikControl
                                                    control="select"
                                                    label="Select Country"
                                                    name="country"
                                                    placeholder="Select Country"
                                                    optionName="country"
                                                    value="code"
                                                    isLoading={loadingCountries}
                                                    options={countries}
                                                    required
                                                />
                                            </Box>
                                            <Box w="70%">
                                                <FormikControl
                                                    control="select"
                                                    label="Select Currency"
                                                    name="currency"
                                                    placeholder="Select Currency"
                                                    optionName="currency"
                                                    value="code"
                                                    isLoading={loadingCurrencies}
                                                    options={currencies}
                                                    required
                                                />
                                            </Box>
                                            <Box w="70%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Custom Domain"
                                                    name="custom_domain"
                                                    placeholder="Custom Domain"
                                                    required={false}
                                                />
                                            </Box>
                                            <Box w="70%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="SMS Sender ID"
                                                    name="sms_sender_id"
                                                    placeholder="SMS Sender ID"
                                                    required={false}
                                                />
                                            </Box>
                                        </Stack>
                                        <Box mt={8}>
                                            <Button
                                                disabled={formik.isSubmitting}
                                                isLoading={formik.isSubmitting}
                                                loadingText="Submitting"
                                                type="submit"
                                                bg="brand.primary"
                                                color="white"
                                                _hover={{ bg: 'brand.green' }}
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Flex >

    )
}

export default EditTenant;