import React, { useContext } from 'react';
import { Context } from '../../context/Context';
import VegetationCheckList from '../vegetationCheck/VegetationCheckList';
import Dashboard from './Dashboard';




function DashIndex() {
    const { user } = useContext(Context);
    if (user.role === "SuperAdmin" || user.role === "Admin") {
        return <Dashboard />
    }
    else {
        return <VegetationCheckList />
    }
}

export default DashIndex;