import React, { useContext, useState } from 'react';
import {
    Box,
    Flex,
    Heading,
    Grid,
    Stack,
    Text,
    Divider,
    SimpleGrid, Button, ButtonGroup, BreadcrumbItem, Breadcrumb, BreadcrumbLink,
    Textarea,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import { useHistory, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import VerificationMap from '../../utils/maps/VerificationMap';
import { Context } from '../../context/Context';
import { renderVegetationCheck, renderVerificationStatus, renderCoordinates } from '../verification/helpers/renderData';
import { getPoints } from '../verification/helpers/getPoints';
import { showToast } from '../../utils/toast';
import config from '../../config'




function VegetationCheck() {
    const { user } = useContext(Context);
    const [rejectionReason, setRejectionReason] = useState("");
    const [isAcceptLoading, setLoadingAccept] = useState(false)
    const [isRejectLoading, setLoadingReject] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const history = useHistory()
    const location = useLocation()
    const { state } = location

    const points = getPoints(state.coord)


    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }


    const action = async (action) => {
        if (action === "NO" && !rejectionReason) {
            showToast('error', "Please enter a reason for rejection")
            return
        }
        action === "YES" ? setLoadingAccept(true) : setLoadingReject(true)
        const data = {
            status: action,
            reject_reason: rejectionReason,
        }
        try {
            const res = await axios.put(`${config.baseUrl}/tie/data/${state._id}`, data, requestOptions)
            if (res.data.status.code !== 100) {
                return showToast('error', res.data.status.desc)
            }
            action === "YES" ? setLoadingAccept(false) : setLoadingReject(false)
            history.goBack()
            showToast('success', res.data.status.desc)
        }
        catch (e) {
            action === "YES" ? setLoadingAccept(false) : setLoadingReject(false)
            showToast('error', e.message)
        }

    }
    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/settlement-check">
                                <Heading as="h4" size="md">Vegetation Check</Heading>
                            </Link>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink href="#">{state._id}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Stack spacing={5}>
                        <Heading as="h5" size="sm" >Unique ID: {state._id}</Heading>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <Box w="100%" pb={8}>
                                <VerificationMap points={points} />
                                <br />
                                <ButtonGroup>
                                    <Button onClick={() => action("YES")} colorScheme="teal" size="md">
                                        <Text fontWeight="normal">Approve</Text>
                                    </Button>
                                    <Button isLoading={isAcceptLoading} onClick={onOpen} colorScheme="red" variant="outline" size="md">
                                        <Text fontWeight="normal">Reject</Text>
                                    </Button>
                                </ButtonGroup>
                            </Box>
                            <Box w="100%" pb={8}>
                                <Stack spacing={4}>
                                    <Heading as="h5" size="sm">
                                        User Provided Info
                                    </Heading>
                                    <SimpleGrid columns={2} spacingY="10px">
                                        <Text fontSize="sm">Anchor:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.partner}
                                        </Heading>
                                        <Text fontSize="md">Crop:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.crop}
                                        </Heading>
                                        <Text fontSize="md">Coordinates:</Text>
                                        <Heading as="h6" size="xs">
                                            {renderCoordinates(state.coord)}
                                        </Heading>
                                        <Text fontSize="md">Country:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.country}
                                        </Heading>
                                        <Text fontSize="md">LGA:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.lga}
                                        </Heading>
                                        <Text fontSize="md">State:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.state}
                                        </Heading>
                                    </SimpleGrid>
                                </Stack>
                            </Box>
                        </Grid>
                        <br />
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <Box w="100%" p={4}>
                                <Stack spacing={4}>
                                    <Heading as="h5" size="sm">
                                        Actions
                                    </Heading>
                                    <SimpleGrid columns={2} spacingY="10px">
                                        <Text fontSize="md">Overlay Check:</Text>
                                        <Box>
                                            {renderVegetationCheck(state.status)}
                                        </Box>
                                        <Text fontSize="md">Vegetation Check:</Text>
                                        <Box>
                                            {renderVegetationCheck(state.status)}
                                        </Box>
                                        <Text fontSize="md">Within Country:</Text>
                                        <Box>
                                            {renderVerificationStatus(state.within_country)}
                                        </Box>
                                        <Text fontSize="md">Within State:</Text>
                                        <Box>
                                            {renderVerificationStatus(state.within_state)}
                                        </Box>
                                        <Text fontSize="md">Within LGA:</Text>
                                        <Box>
                                            {renderVerificationStatus(state.within_lga)}
                                        </Box>
                                    </SimpleGrid>
                                </Stack>
                            </Box>
                            <Box w="100%" p={4}>
                                <Stack spacing={4}>
                                    <Heading as="h5" size="sm">
                                        Returned Information
                                    </Heading>
                                    <SimpleGrid columns={2} spacingY="10px">
                                        <Text fontSize="md">Country:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.returned_country}
                                        </Heading>
                                        <Text fontSize="md">LGA:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.returned_lga}
                                        </Heading>
                                        <Text fontSize="md">State:</Text>
                                        <Heading as="h6" size="xs">
                                            {state.returned_state}
                                        </Heading>
                                    </SimpleGrid>
                                </Stack>
                            </Box>
                        </Grid>
                    </Stack>
                </Box>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Reason for Rejection</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Textarea
                                onChange={(e) => setRejectionReason(e.target.value)}
                                border="0"
                                bg="lightGrey"
                                focusBorderColor="brand.green" />
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button
                                variant="ghost"
                                isLoading={isRejectLoading}
                                colorScheme="red"
                                onClick={() => action("NO")}>Submit</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </Flex >

    )
}

export default VegetationCheck;