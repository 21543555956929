export const getPoints = (coord) => {

    if (!coord || !coord.length) {
        return []
    }
    const coordPair = coord.split(",")
    const polygon = coordPair.map(pair => {
        const obj = {
            lat: parseFloat(pair.split(":")[0]),
            lng: parseFloat(pair.split(":")[1])
        }
        return obj
    })

    return polygon
}