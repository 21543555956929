import React, { useMemo, useContext, useState, useRef } from 'react';
import {
    Box,
    HStack,
    Heading,
    Select,
    Skeleton,
    Flex,
    Text,
    Icon,
    Grid,
    InputGroup,
    InputLeftElement,
    Input,
    Button,
    VStack, Spinner, Divider, IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Stack, Popover, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverTrigger, Portal
} from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { FiEye, FiSettings } from 'react-icons/fi';
import { useTable } from 'react-table'
import dayjs from 'dayjs';
import { AiFillCheckCircle, AiFillPauseCircle, AiOutlineSearch } from 'react-icons/ai'
import { FaTimesCircle } from 'react-icons/fa'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { RiContrastLine } from 'react-icons/ri'
import { useVerification, useVerificationCards, usePartners, useProviders } from './helpers/fetcher';
import config from '../../config'
import { useStates, useCrops } from '../../utils/data/fetcher';
import { Context } from '../../context/Context';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import { renderOverlay, renderVegetationCheck, renderVerificationStatus, renderAlternateVegetationCheck } from './helpers/renderData';
import { useTenants } from '../tenants/helpers/fetcher';
import Can from '../../utils/rbac/Can';
import { showToast } from '../../utils/toast';
import { MdAdd } from 'react-icons/md';



function VerificationList() {
    const history = useHistory()
    const { user } = useContext(Context)
    const [pageIndex, setPageIndex] = useState(0)
    const [limit, setLimit] = useState(10)
    const [selectedTenant, setSelectedTenant] = useState("")
    const [selectedStatus, setSelectedStatus] = useState("")
    const [selectedPartner, setSelectedPartner] = useState("")
    const [selectedProvider, setSelectedProvider] = useState("")
    const [selectedState, setSelectedState] = useState("")
    const [selectedCrop, setSelectedCrop] = useState("")
    const [selectedUploadTenant, setSelectedUploadTenant] = useState("")
    const [selectedFile, setSelectedFile] = useState(null)
    const [isUploading, setUploading] = useState(false)
    const [query, setQuery] = useState("")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [downloading, setDownloading] = useState(false)
    const [type, setType] = useState('xlsx')

    const fileInput = useRef(null);

    const { role, tenant } = user

    const filterPage = pageIndex > 0 ? `&page=${pageIndex}` : '';
    const filterTenant = selectedTenant ? `&tenant=${selectedTenant}` : '';
    const filterStatus = selectedStatus ? `&overall_status=${selectedStatus}` : '';
    const filterPartner = selectedPartner ? `&partner=${selectedPartner}` : '';
    const filterProvider = selectedProvider ? `&provider=${selectedProvider}` : '';
    const filterState = selectedState ? `&state=${selectedState}` : '';
    const filterCrop = selectedCrop ? `&crop=${selectedCrop}` : '';
    const filterQuery = query ? `&query=${query}` : '';
    const tenantState = tenant?.country ? `?country=${tenant.country}` : ''

    const filterPath = filterPage + filterTenant + filterState + filterStatus + filterProvider + filterCrop + filterPartner + filterQuery;
    const filterCards = filterTenant + filterState + filterStatus + filterProvider + filterCrop + filterPartner + filterQuery;

    const { data: entity, isLoading } = useVerification(`tie/data?per_page=${limit}${filterPath}`, user.token)
    const { data: cards, isLoading: loadingCards } = useVerificationCards(`dashboard/tie?data=0${filterCards}`, user.token)

    const passed = cards.filter(card => card.overall_status === "PASS")
    const failed = cards.filter(card => card.overall_status === "FAIL")
    const pending = cards.filter(card => card.overall_status !== "PASS" && card.overall_status !== "FAIL")
    const total = cards.reduce((acc, card) => acc + parseInt(card.total_no), 0)

    const { data: partners } = usePartners(`partners/tie`, user.token)
    const { data: providers } = useProviders(`providers/tie`, user.token)
    const { states, } = useStates(`tie/states${tenantState}`, user.token)
    const { crops, } = useCrops("crops/tie", user.token)

    const { data: tenantEntity } = useTenants(`tenants/tie`, user.token)
    const tenants = tenantEntity.tenants ?? []
    const columns = useMemo(
        () => [
            {
                Header: "Unique ID",
                accessor: "_id",

            },
            {
                Header: "Cluster Name",
                accessor: "cluster_name"
            },
            {
                Header: "Created",
                accessor: "created",
                Cell: ({ value }) => {
                    return <span>{value ? dayjs(value).format('YYYY/MM/DD hh:mm') : ''}</span>
                }

            },
            {
                Header: "Result",
                accessor: "overall_status",
                Cell: ({ value }) => renderVegetationCheck(value)
            },
            {
                Header: "Vegetation",
                accessor: "status",
                Cell: ({ value }) => renderAlternateVegetationCheck(value)
            },
            {
                Header: "Within State",
                accessor: "within_state",
                Cell: ({ value }) => renderVerificationStatus(value)
            },
            {
                Header: "Overlay",
                accessor: "overlay",
                Cell: ({ value }) => renderOverlay(value)
            },

            {
                Header: "View",
                Cell: ({ row }) => {
                    const { original } = row
                    return (
                        <Button variant="ghost" onClick={() => history.push(`/verification/${original._id}`, original)}>
                            <FiEye style={{ fontSize: 18 }} />
                        </Button>
                    )
                }

            },
            {
                Header: "Analyze",
                Cell: ({ row }) => {
                    const { original } = row

                    // if (original.date_of_interest) {
                    //     return (

                    //         < Button variant="ghost" onClick={() => history.push(`/quick-verification/farm-analysis`, original)
                    //         }>
                    //             <FiSettings style={{ fontSize: 18 }} />
                    //         </Button >
                    //     )
                    // }
                    return (
                        <Button variant="ghost" onClick={() => history.push(`/dashboard/farm-analysis`, original)}>
                            <FiSettings style={{ fontSize: 18 }} />
                        </Button>
                    )
                }

            },
        ], [history]
    )
    const data = useMemo(() => entity.aaData || [], [entity])

    const tableInstance = useTable({ columns, data })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    const setPrev = () => {
        setPageIndex(pageIndex - 1)
    }

    const setNext = () => {
        setPageIndex(pageIndex + 1)
    }

    const uploadFile = async () => {
        if (!selectedFile) {
            showToast('error', 'Please select a file')
            return
        }
        if (!selectedUploadTenant) {
            showToast('error', 'Please select a tenant')
            return
        }
        setUploading(true)
        const data = new FormData()
        data.append('file', selectedFile)
        data.append('tenant', selectedUploadTenant)
        await axios({
            url: `${config.baseUrl}/tie/upload`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "Authorization": `Bearer ${user.token}`
            },
        }).then(res => {
            setUploading(false)
            onClose()
            showToast('success', "Data Added Successfully")
        }).catch(err => {
            setUploading(false)
            showToast('error', err.message)
        })
    }


    const download = async () => {
        setDownloading(true)
        await axios({
            url: `${config.baseUrl}/export/tie?type=${type}${filterPath}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                "Authorization": `Bearer ${user.token}`
            },
        }).then(response => {
            setDownloading(false)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `download.${type}`);
            document.body.appendChild(link);
            link.click();
            link.remove();

        }).catch(err => {
            setDownloading(false)
            showToast('error', err.message)
        })

    }


    console.log(entity)
    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Verification List</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="rgb(255,255,255,0.55)" p={5} rounded="lg" mt={5}>
                    <HStack spacing={5}>
                        <Can
                            role={role}
                            perform="tenants:visit"
                            yes={() => (
                                <Select
                                    placeholder="Tenants"
                                    onChange={(e) => {
                                        setSelectedTenant(e.target.value)
                                    }}

                                    borderColor="brand.green"

                                >
                                    <option value="">All</option>
                                    {
                                        tenants.map(item => (
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        ))
                                    }
                                </Select>

                            )}
                            no={() => null}
                        />
                        <Select
                            borderColor="brand.green"
                            placeholder="Sources"
                            onChange={(e) => {
                                setSelectedProvider(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            {
                                providers.map((item, i) => (
                                    <option key={i} value={item.provider}>{item.provider}</option>
                                ))
                            }
                        </Select>
                        <Select
                            borderColor="brand.green"
                            placeholder="Organizations"
                            onChange={(e) => {
                                setSelectedPartner(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            {
                                partners.map((item, i) => (
                                    <option key={i} value={item.partner}>{item.partner}</option>
                                ))
                            }
                        </Select>
                        <Select
                            borderColor="brand.green"
                            placeholder="Result"
                            onChange={(e) => {
                                setSelectedStatus(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            <option value="PASS">PASS</option>
                            <option value="FAIL">FAIL</option>
                            <option value="IN PROGRESS">IN PROGRESS</option>
                        </Select>
                        <Select
                            borderColor="brand.green"
                            placeholder="State / Region"
                            onChange={(e) => {
                                setSelectedState(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            {states.map(state => (
                                <option key={state.state} value={state.state}>{state.state}</option>
                            ))}
                        </Select>

                        <Select
                            borderColor="brand.green"
                            placeholder="Crop"
                            onChange={(e) => {
                                setSelectedCrop(e.target.value)
                            }}
                        >
                            <option value="">All</option>
                            {crops.map((item, i) => (
                                <option key={i} value={item.crop}>{item.crop}</option>
                            ))}
                        </Select>
                    </HStack>
                    <br />
                    <Grid gap={4} templateColumns="repeat(4, 1fr)">
                        <Box shadow="md" p={5} bg="white" rounded="md">
                            <Flex justify="space-between">
                                <Skeleton isLoaded={!loadingCards}>
                                    <Box>
                                        <Text>Total</Text>
                                        <Heading as="h2" size="xl">{total}</Heading>
                                    </Box>
                                </Skeleton>
                                <Icon as={RiContrastLine} color="blue.500" w={5} h={5} />
                            </Flex>
                        </Box>
                        <Box shadow="md" p={5} bg="white" rounded="md">
                            <Flex justify="space-between">
                                <Skeleton isLoaded={!loadingCards}>
                                    <Box>
                                        <Text>Passed</Text>
                                        <Heading as="h2" size="xl">{passed[0]?.total_no ?? 0}</Heading>
                                    </Box>
                                </Skeleton>
                                <Icon as={AiFillCheckCircle} color="green.500" w={5} h={5} />
                            </Flex>
                        </Box>
                        <Box shadow="md" p={5} bg="white" rounded="md">
                            <Flex justify="space-between">
                                <Skeleton isLoaded={!loadingCards}>
                                    <Box>
                                        <Text>Failed</Text>
                                        <Heading as="h2" size="xl">{failed[0]?.total_no ?? 0}</Heading>
                                    </Box>
                                </Skeleton>
                                <Icon as={FaTimesCircle} color="red.500" w={5} h={5} />
                            </Flex>
                        </Box>
                        <Box shadow="md" p={5} bg="white" rounded="md">
                            <Flex justify="space-between">
                                <Skeleton isLoaded={!loadingCards}>
                                    <Box>
                                        <Text>Pending</Text>
                                        <Heading as="h2" size="xl">{pending[0]?.total_no ?? 0}</Heading>
                                    </Box>
                                </Skeleton>
                                <Icon as={AiFillPauseCircle} color="orange.300" w={5} h={5} />
                            </Flex>
                        </Box>
                    </Grid>
                    <br />
                    <Flex justify="space-between">
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<Icon as={AiOutlineSearch} color="gray.500" />}
                            />
                            <Input type="phone" placeholder="Search" w={60} bg="white" onChange={(e) => setQuery(e.target.value)} />
                        </InputGroup>
                        <HStack spacing={4}>
                            <Can
                                role={role}
                                perform="verification:upload"
                                yes={() => (
                                    <Button colorScheme="teal" size="md" onClick={onOpen}>
                                        <Text fontWeight="normal">Upload</Text>
                                    </Button>

                                )}
                                no={() => null}
                            />

                            <Popover placement="left" closeOnBlur={true}>
                                <PopoverTrigger>
                                    <Button colorScheme="teal" size="md">
                                        <Text fontWeight="normal">Download</Text>
                                    </Button>
                                </PopoverTrigger>
                                <Portal>
                                    <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <Stack spacing={4} p={5}>
                                            <Heading as="h5" size="sm" >Select File Type</Heading>
                                            <Select
                                                onChange={(e) => setType(e.target.value)}
                                                placeholder="Select File Type"
                                                defaultValue={type}>
                                                <option value="xlsx">XLSX</option>
                                                <option value="csv">CSV</option>
                                            </Select>
                                            <Button isLoading={downloading} onClick={download} colorScheme="teal">
                                                Download
                                            </Button>
                                        </Stack>
                                    </PopoverContent>
                                </Portal>
                            </Popover>
                        </HStack>

                    </Flex>
                    <Box mt={5} overflow="auto" rounded="md" bg="white">
                        <table {...getTableProps()}>
                            <thead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (

                                                    <th {...column.getHeaderProps()}>
                                                        {
                                                            column.render('Header')}
                                                    </th>
                                                ))}
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {// Loop over the table rows
                                    rows.map(row => {
                                        // Prepare the row for display
                                        prepareRow(row)
                                        return (
                                            // Apply the row props
                                            <tr {...row.getRowProps()}>
                                                {// Loop over the rows cells
                                                    row.cells.map(cell => {
                                                        // Apply the cell props
                                                        return (
                                                            <td {...cell.getCellProps()}>
                                                                {// Render the cell contents
                                                                    cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}

                            </tbody>
                        </table>
                        {isLoading &&
                            <VStack mt={4}>
                                <Spinner color="brand.green" />
                            </VStack>}
                        {!isLoading && entity.aaData?.length === 0 && <Text textAlign="center">No records found</Text>}
                    </Box>
                    <Flex justify="flex-end" mt={4}>
                        <HStack spacing={2}>
                            <Text fontSize="sm">Total Records:</Text>
                            <Text fontSize="sm">{entity?.iTotalRecords ?? '-'}</Text>
                            <Divider orientation="vertical" />
                            <Select
                                onChange={(e) => setLimit(e.target.value)}
                                placeholder="Select option"
                                defaultValue={"10"} w={"20%"}>
                                <option value="10">10 / page</option>
                                <option value="20">20 / page</option>
                                <option value="50">50 / page</option>
                                <option value="100">100 / page</option>
                            </Select>
                            <Divider orientation="vertical" />
                            <IconButton variant="ghost" disabled={pageIndex <= 0} onClick={setPrev} aria-label="Previous Page" icon={<FiChevronLeft />} size="xs" color="brand.primary" />
                            <Text fontSize="sm"> Page {pageIndex + 1} of {entity ? entity.total_pages : '-'}</Text>
                            <IconButton variant="ghost" disabled={pageIndex >= entity.total_pages} onClick={setNext} aria-label="Previous Page" icon={<FiChevronRight />} size="xs" color="brand.primary" />

                            <Text fontSize="sm">Go to Page</Text>
                            <NumberInput
                                onChange={(page) => {
                                    if (page > entity.total_pages || page < 1) {
                                        return
                                    }
                                    setPageIndex(page - 1)
                                }}
                                min={0}
                                size="sm" w={20}
                                defaultValue={pageIndex + 1}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>

                        </HStack>
                    </Flex>
                </Box>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Upload Data</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Select
                                placeholder="Tenants"
                                onChange={(e) => {
                                    setSelectedUploadTenant(e.target.value)
                                }}

                                borderColor="brand.green"

                            >
                                {
                                    tenants.map(item => (
                                        <option key={item._id} value={item._id}>{item.name}</option>
                                    ))
                                }
                            </Select>
                            <br />
                            <HStack spacing={2}>
                                <Button colorScheme="gray" mr={3} onClick={() => {
                                    fileInput.current.value = null
                                    fileInput.current.click()
                                }} leftIcon={<MdAdd />} >
                                    Select File
                                </Button>
                                <Text fontSize="sm">{selectedFile && selectedFile.name}</Text>
                            </HStack>
                            <br />
                            <input
                                style={{ display: 'none' }}
                                ref={fileInput}
                                accept=".xls,.xlsx"
                                type="file"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={onClose}>
                                Close
                            </Button>

                            <Button variant="ghost" colorScheme="teal" mr={3}>
                                <a href={'/overlay_check_sample.xlsx'} download="Template">
                                    Download Template
                                </a>
                            </Button>
                            <Button
                                isLoading={isUploading}
                                colorScheme="teal"
                                onClick={() => uploadFile()}>Submit</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            </Box>

        </Flex >

    )
}

export default VerificationList;