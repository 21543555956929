import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Box,
    Flex,
    Heading,
    InputGroup,
    Input,
    HStack,
    IconButton,
    InputRightElement,
    Stack,
    Button,
    Text, Icon, ButtonGroup, BreadcrumbItem, BreadcrumbLink,
    FormControl,
    FormErrorMessage,
    Link,
    Breadcrumb,
} from "@chakra-ui/react";
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { FiCheck } from 'react-icons/fi';
import { GrCopy } from 'react-icons/gr';
import Drawer from '../layout/Drawer';
import { showToast } from '../../utils/toast';
import { useProfile } from '../profile/helpers/fetcher';
import { addWebHookValidationSchema } from './helpers/validationSchema';
import ProfileHeader from '../layout/ProfileHeader';
import config from '../../config'
import { Context } from '../../context/Context';


function Developer() {
    const [loading, setLoading] = useState("")
    const [key, setKey] = useState("")
    const [visible, setVisible] = useState(false)
    const inputRef = useRef(null);

    const { user, hasViewedKey, dispatchHasViewedKey } = useContext(Context);

    const { data: entity } = useProfile(user.token)

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    useEffect(() => {
        if (entity) {
            setKey(entity.me.secret_key)
        }
    }, [entity]);

    const initialValues = {
        webhook: entity ? entity.me.webhook : '',
    }

    function copyToClipboard(e) {
        navigator.clipboard.writeText(key);
        showToast('success', 'Copied to Clipboard')
    };

    const showKey = (e) => {
        if (hasViewedKey) {
            showToast("error", "You have already viewed this key")
            return;
        }
        if (!hasViewedKey && !visible) {
            setVisible(true);
            setTimeout(() => {
                dispatchHasViewedKey({ type: 'HAS_VIEWED_KEY' })
            }, 500)
            copyToClipboard(e)
            return;
        }
    }


    const generateKey = async () => {
        setLoading("generateKey")
        try {
            const res = await axios.post(`${config.baseUrl}/tie/generate_key`, {}, requestOptions)
            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }
            else {
                showToast("success", res.data.status.desc)
                setKey(res.data.entity.secret_key)
            }
        }
        catch (e) {
            showToast("error", e.message)

        }
        finally {
            setLoading("")
            dispatchHasViewedKey({ type: 'RESET' })
        }
    }

    const saveWebhookUrl = async (values, onSubmitProps) => {
        try {
            //run check
            const checkRes = await axios.post(values.webhook)

            if (checkRes.status === 200) {
                const res = await axios.post(`${config.baseUrl}/tie/webhook`, values, requestOptions)
                if (res.data.status.code !== 100) {
                    showToast("error", res.data.status.desc)
                }

                else {
                    showToast("success", res.data.status.desc)
                }
            }
            else {
                showToast("error", "Could not get 200 response from POST request to web hook ")
            }

        }
        catch (e) {
            if (e.message) {
                showToast("error", e.message)
            }
            else {
                showToast("error", "Could not get 200 response from POST request to web hook")
            }

        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }

    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Breadcrumb>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>
                                <Heading as="h4" size="md">Settings</Heading>
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink href="#">Developer</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Stack spacing={10}>
                        <Box>
                            <Heading as="h5" size="sm">API Keys</Heading>
                            <Flex justify="space-between" mt={5} align="flex-start">
                                <Box w="50%">
                                    <HStack spacing={2}>
                                        <Input
                                            ref={inputRef}
                                            value={key}
                                            isReadOnly
                                            type={visible ? 'text' : 'password'}
                                            placeholder="API Key" />
                                        {/* {visible && }<IconButton aria-label="Copy to clipboarde" icon={<GrCopy />} onClick={copyToClipboard} /> */}
                                        <IconButton aria-label="view key" disabled={hasViewedKey} icon={<GrCopy />} onClick={(e) => showKey(e)} />
                                    </HStack>

                                    <br />
                                    <Button isLoading={loading === "generateKey"} onClick={generateKey} colorScheme="teal" size="sm">
                                        <Text fontWeight="normal">Generate a new key</Text>
                                    </Button>
                                </Box>
                                <Stack spacing={4} direction="row" align="center">
                                    <Link href="https://documenter.getpostman.com/view/5402398/TWDZFvNE" isExternal>
                                        <Button colorScheme="teal" size="md">
                                            <Text fontWeight="normal">View Documentation</Text>
                                        </Button>
                                    </Link>
                                </Stack>
                            </Flex>
                        </Box>

                        <Box>
                            <Heading as="h5" size="sm">Webhook URL</Heading>
                            <Text fontSize="sm" mt={4}>Enter Webhook URL</Text>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={saveWebhookUrl}
                                validationSchema={addWebHookValidationSchema}
                                enableReinitialize={true}
                            >
                                {formik => {
                                    return (
                                        <Form>
                                            <Box mt={4} w="50%">
                                                <Field name="webhook">
                                                    {({ field, form }) => (

                                                        <FormControl
                                                            isInvalid={form.errors['webhook'] && form.touched['webhook']}
                                                        >
                                                            <InputGroup>
                                                                <Input
                                                                    {...field}
                                                                    type="text"
                                                                    placeholder="https://" />
                                                                <InputRightElement
                                                                    pointerEvents="none"
                                                                    children={
                                                                        formik.isValid ?
                                                                            <Icon as={FiCheck} color="brand.primary" /> : null
                                                                    }
                                                                />
                                                            </InputGroup>
                                                            <FormErrorMessage>{form.errors['webhook']}</FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </Box>
                                            <br />
                                            <ButtonGroup>
                                                <Button
                                                    size="sm"
                                                    disabled={formik.isSubmitting}
                                                    isLoading={formik.isSubmitting}
                                                    loadingText="Submitting"
                                                    type="submit"
                                                    bg="brand.primary"
                                                    color="white"
                                                    _hover={{ bg: 'brand.green' }}
                                                >
                                                    <Text fontWeight="normal">Save Web Hook</Text>
                                                </Button>
                                            </ButtonGroup>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Flex >
    )
}

export default Developer;