import React, { createContext, useReducer, useEffect } from 'react';
import { authReducer } from '../reducers/authReducer'
import { apiKeyReducer } from '../reducers/apiKeyReducer';

export const Context = createContext();


const ContextProvider = ({ children }) => {
    const [user, dispatchUser] = useReducer(authReducer, {}, () => {
        const localData = localStorage.getItem('tieUserData')
        return localData ? JSON.parse(localData) : {}
    });

    const [hasViewedKey, dispatchHasViewedKey] = useReducer(apiKeyReducer, {}, () => {
        const localData = localStorage.getItem('hasViewedKey')
        return localData ? JSON.parse(localData) : false
    });

    useEffect(() => {
        localStorage.setItem('hasViewedKey', hasViewedKey)
    }, [hasViewedKey])

    useEffect(() => {
        localStorage.setItem('tieUserData', JSON.stringify(user))
    }, [user])
    return (
        <Context.Provider
            value={{ user, dispatchUser, hasViewedKey, dispatchHasViewedKey }}
        >
            {children}
        </Context.Provider>
    )
}

export default ContextProvider;