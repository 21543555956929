import useSWR from 'swr';
import config from '../../../config'
import { fetcher } from '../../../utils/data/fetcher';


export const useProfile = (token) => {
    const { data, error } = useSWR([`${config.baseUrl}/account/tie`, token], fetcher)
    //show error here
    return {
        data: data ? data.entity : null,
        isLoading: !error && !data,
        isError: error
    }
}

// export const useUser = (path, token) => {
//     const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
//     //show error here
//     return {
//         data: data ? data.user : {},
//         isLoading: !error && !data,
//         isError: error
//     }
// }


