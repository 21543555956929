import React from 'react';
import { Doughnut } from 'react-chartjs-2';

function VegetationDoughnutChart({ yes, no, inProgress }) {

    const data = {
        labels: ['Passed', 'Failed', 'In Progress'],
        datasets: [
            {
                label: 'Sales for Jan',
                data: [
                    yes[0]?.total_no ?? 0,
                    no[0]?.total_no ?? 0,
                    inProgress[0]?.total_no ?? 0,
                ],
                backgroundColor: ["rgba(20,182,167,0.8)", "rgba(234,27,42,0.5)", "rgba(255,179,53,0.8)"],
            }
        ],


    }

    const options = {
        legend: {
            position: 'right',
            labels: {
                boxWidth: 15,
                padding: 15
            }
        }

    }

    return <Doughnut data={data} options={options} />;
}


export default VegetationDoughnutChart;