import React, { useContext } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Stack,
    Grid,
    Breadcrumb, BreadcrumbItem, BreadcrumbLink
} from "@chakra-ui/react";
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { pickBy, identity } from 'lodash';
import axios from 'axios';
import Drawer from '../layout/Drawer';
import FormikControl from '../../utils/form/FormikControl';
import { editUserValidationSchema } from './helpers/validationSchemas';
import { useTenants } from '../tenants/helpers/fetcher';

import config from '../../config'
import { showToast } from '../../utils/toast';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';
import Can from '../../utils/rbac/Can';



function EditUser() {
    const history = useHistory()
    const location = useLocation()
    const { state } = location
    const { user } = useContext(Context)
    const { role } = user

    const userId = location.pathname.split("/")[3]

    const isSuperAdmin = role === "SuperAdmin"

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    const roles = isSuperAdmin ? [
        {
            name: 'Admin',
            value: 'Admin'
        },
        {
            name: 'RSE Agent',
            value: 'RSE Agent'
        },

    ] : [{
        name: 'Admin',
        value: 'Admin'
    },]

    const initialValues = {
        first_name: state?.first_name ?? "",
        last_name: state?.last_name ?? "",
        email: state?.email ?? "",
        mobile: state?.mobile ?? "",
        bvn: state?.bvn ?? "",
        role: state?.role ?? "",
        tenant_id: state?.tenant?._id ?? "",
    }
    const { data: entity, isLoading } = useTenants(`tenants/tie`, user.token)

    const tenants = entity.tenants ?? []


    async function onSubmit(values, onSubmitProps) {

        const body = pickBy(values, identity)
        try {
            const res = await axios.put(`${config.baseUrl}/account/${userId}/tie`, body, requestOptions)

            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }

            else {
                showToast("success", "Successfully edited user")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }
    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%">
                <Flex justify="space-between">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/users">
                                <Heading as="h4" size="md">User Management</Heading>
                            </Link>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink href="#">Edit user</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Stack spacing={4}>
                        <Heading as="h5" size="sm">Edit User</Heading>
                        <Box w="100%">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                validationSchema={editUserValidationSchema}
                            >
                                {formik => (
                                    <Form>
                                        <Stack spacing={8}>
                                            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                                                <Box w="90%">
                                                    <FormikControl
                                                        control="input"
                                                        type="text"
                                                        label="First Name"
                                                        name="first_name"
                                                        placeholder="First Name"
                                                    />
                                                </Box>
                                                <Box w="90%">
                                                    <FormikControl
                                                        control="input"
                                                        type="text"
                                                        label="Last Name"
                                                        name="last_name"
                                                        placeholder="Last Name"
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                                <Box w="90%">
                                                    <FormikControl
                                                        control="input"
                                                        type="email"
                                                        disabled
                                                        label="Email"
                                                        name="email"
                                                        placeholder="Email"
                                                    />
                                                </Box>
                                                <Box w="90%">
                                                    <FormikControl
                                                        control="input"
                                                        type="text"
                                                        label="Phone Number"
                                                        name="mobile"
                                                        placeholder="Phone Number"
                                                        required={false}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                                <Box w="90%">
                                                    <FormikControl
                                                        control="select"
                                                        label="Select Role"
                                                        name="role"
                                                        placeholder="Select Role"
                                                        optionName="name"
                                                        value="value"
                                                        isLoading={false}
                                                        options={roles}
                                                        required
                                                    />
                                                </Box>
                                                <Can
                                                    role={role}
                                                    perform="tenants:visit"
                                                    yes={() => (
                                                        <Box w="90%">
                                                            <FormikControl
                                                                control="select"
                                                                label="Select Tenant"
                                                                name="tenant_id"
                                                                placeholder="Select Tenant"
                                                                optionName="name"
                                                                value="_id"
                                                                isLoading={isLoading}
                                                                options={tenants}
                                                            />
                                                        </Box>

                                                    )}
                                                    no={() => null}
                                                />


                                            </Grid>
                                            <Heading as="h5" size="sm">Other information</Heading>
                                            <Box w="45%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="BVN"
                                                    name="bvn"
                                                    placeholder="BVN"
                                                    required={false}
                                                />
                                            </Box>
                                        </Stack>
                                        <Box mt={8}>
                                            <Button
                                                disabled={formik.isSubmitting}
                                                isLoading={formik.isSubmitting}
                                                loadingText="Submitting"
                                                type="submit"
                                                bg="brand.primary"
                                                color="white"
                                                _hover={{ bg: 'brand.green' }}
                                            >
                                                Submit
                                        </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Stack>
                </Box>
            </Box>

        </Flex >

    )
}

export default EditUser;