import React, { useContext } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Stack,
    Grid,
    Breadcrumb, BreadcrumbItem, BreadcrumbLink
} from "@chakra-ui/react";
import { useHistory, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import axios from 'axios';
import Drawer from '../layout/Drawer';
import FormikControl from '../../utils/form/FormikControl';
import { addCurrencyValidationSchema } from './helpers/validationSchemas';
import config from '../../config'
import { showToast } from '../../utils/toast';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';

const initialValues = {
    currency: '',
    code: '',
}

function AddCurrency() {
    const history = useHistory()

    const { user } = useContext(Context)


    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    async function onSubmit(values, onSubmitProps) {
        try {
            const res = await axios.post(`${config.baseUrl}/currency`, values, requestOptions)
            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }

            else {
                showToast("success", "Successfully added currency")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }
    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/currencies">
                                <Heading as="h4" size="md">Currencies</Heading>
                            </Link>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink href="#">Add Currency</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5} >
                    <Stack spacing={4}>
                        <Heading as="h5" size="sm">Add New Currency</Heading>
                        <Box w="100%">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                validationSchema={addCurrencyValidationSchema}
                            >
                                {formik => (
                                    <Form>
                                        <Stack spacing={8}>
                                            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                                                <Box w="90%">
                                                    <FormikControl
                                                        control="input"
                                                        type="text"
                                                        label="Name"
                                                        name="currency"
                                                        placeholder="Currency Name"
                                                    />
                                                </Box>

                                            </Grid>

                                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                                <Box w="90%">
                                                    <FormikControl
                                                        control="input"
                                                        type="text"
                                                        label="Code"
                                                        name="code"
                                                        placeholder="Currency Code"
                                                        required
                                                    />
                                                </Box>
                                            </Grid>
                                        </Stack>
                                        <Box mt={8}>
                                            <Button
                                                disabled={formik.isSubmitting}
                                                isLoading={formik.isSubmitting}
                                                loadingText="Submitting"
                                                type="submit"
                                                bg="brand.primary"
                                                color="white"
                                                _hover={{ bg: 'brand.green' }}
                                            >
                                                Submit
                                        </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Flex>
    )
}

export default AddCurrency;