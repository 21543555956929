import React, { useState } from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import config from '../../config';
import { getPoints } from '../../views/verification/helpers/getPoints';


function InsightsMap(props) {


    const setFillColor = (status) => {
        if (status === "PASS") {
            return 'green'
        }
        else if (status === "FAIL") {
            return 'red'
        }
        else {
            return "orange"
        }
    }



    const [center, setCenter] = useState({ lat: 9.0820, lng: 8.6753 })
    return (
        <LoadScript
            googleMapsApiKey={config.mapsKey}
        >
            <GoogleMap
                mapTypeId="satellite"
                center={center}
                mapContainerStyle={{ height: props.height ? props.height : '60vh' }}
                zoom={7}
            >
                <>

                    {props.data.map((item, i) => {
                        const points = getPoints(item.coord)
                        return (
                            <Polygon
                                key={i}
                                onClick={() => {
                                    console.log(points)

                                    setCenter({ lat: parseFloat(points[1].lat), lng: parseFloat(points[1].lng) })
                                    props.setActivePolygon(item)
                                }}
                                paths={points}
                                options={
                                    {
                                        fillColor: setFillColor(item.overall_status),
                                        fillOpacity: 0.7,
                                        strokeColor: 'black',
                                        strokeOpacity: 1,
                                        strokeWeight: 2,
                                        clickable: true,
                                        draggable: false,
                                        editable: false,
                                        geodesic: false,
                                        zIndex: 1
                                    }
                                } />
                        )
                    })}
                </>
            </GoogleMap>
        </LoadScript>
    )
}

export default InsightsMap;




// AIzaSyAE5beByDMsRgJwMDLutqutRI_ZjxnyCJs