import axios from 'axios';
import useSWR from 'swr';
import config from '../../config'



export const fetcher = (url, token) =>
    axios.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(res => res.data)



export const useStates = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    return {
        states: data ? data.entity : [],
        isLoadingStates: !error && !data,
        isError: error,
    }
}

export const useStates2 = (path, token) => {
    const { data, error } = useSWR([`${config.prodBaseUrl}/${path}`, token], fetcher)
    return {
        states: data ? data.entity : [],
        isLoadingStates: !error && !data,
        isError: error,
    }
}

export const useCrops = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)


    return {
        crops: data ? data.entity : [],
        isLoadingCrops: !error && !data,
        isError: error,
    }
}

export const useAssociations = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    return {
        associations: data ? data.entity : [],
        isLoadingCrops: !error && !data,
        isError: error,
    }
}


export const useGeoData = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.entity : [],
        isLoading: !error && !data,
        isError: error
    }
}


export const useImageData = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)

    return {
        data,
        isLoading: !error && !data,
        isError: error
    }
}




