import React, { useContext } from 'react';
import { Grid, Box, Center, Heading, VStack, Button, Text, Image } from "@chakra-ui/react";
import axios from 'axios';
import { Formik, Form } from 'formik';
import { loginValidationSchema } from './helpers/validationSchemas';
import { Context } from '../../context/Context';
import FormikControl from '../../utils/form/FormikControl';
import ImageBinary from '../../assets/images/image-binary.png';
import config from '../../config'
import { showToast } from '../../utils/toast';
import { useTenant } from '../tenants/helpers/fetcher';



const initialValues = {
    email: "",
    password: ""
}

function Login() {

    const { dispatchUser } = useContext(Context)


    const { hostname } = window.location
    const { data: tenant, isLoading } = useTenant(`tenant/domain/${hostname}/tie`)

    async function onSubmit(values, onSubmitProps) {
        try {
            const res = await axios.post(`${config.baseUrl}/account/login/tie`, values)
            if (res.data.status.code !== 100) {
                showToast("error", res.data.status.desc)
            }

            else {
                const user = { ...res.data.entity.user, token: res.data.entity.token }
                dispatchUser({
                    type: 'LOG_IN', user: user
                });
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }

    return (
        <>
            <Grid templateColumns="repeat(2, 1fr)" gap={0}>
                <Box w="100%" h="100vh" bg="blue.500">
                    <Image src={ImageBinary} height="100vh" width="50vw" />
                    <Box zIndex={2} position="absolute" top={0} left={0} bg="rgba(20, 182, 167, 0.65)" h="100vh" w="50vw" ></Box>
                </Box>
                <Center w="100%" h="100vh" position="relative">
                    <Box w="60%">
                        <VStack spacing={12} align="stretch">
                            {/* <VStack>
                                {!isLoading && <img src={tenant?.logo ?? "https://res.cloudinary.com/ddjyel5tz/image/upload/v1608236779/logo_yhekkq.jpg"} alt="Logo" width={120} />}
                            </VStack> */}
                            <Formik
                                initialValues={initialValues}
                                validationSchema={loginValidationSchema}
                                onSubmit={onSubmit}
                            >
                                {(formik) => (
                                    <Form>
                                        <VStack spacing={6}>
                                            <Heading as="h4" size="md">Sign In</Heading>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    name="email"
                                                    placeholder="Enter email address"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter password"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <Button
                                                    isFullWidth
                                                    disabled={formik.isSubmitting}
                                                    isLoading={formik.isSubmitting}
                                                    loadingText="Logging In"
                                                    type="submit"
                                                    bg="brand.green"
                                                    borderColor="brand.green"
                                                    color="white"
                                                    _active={{ bg: "brand.green" }}
                                                    _hover={{ bg: "brand.green" }}
                                                    size="lg">LOG IN</Button>
                                            </Box>
                                        </VStack>
                                    </Form>
                                )}
                            </Formik>
                        </VStack>

                        <Box mt={4} textAlign="center">
                            <Text fontSize="md">Forgot Password?</Text>
                        </Box>
                        <Box bottom={5} position="absolute" left="33%">
                            <Text >2020. TIE. All Rights Reserved</Text>
                        </Box>
                    </Box>
                </Center>
            </Grid>
        </>
    )
}

export default Login;