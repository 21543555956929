import React, { useContext, useMemo } from 'react';
import {
    Box,
    Button,
    Flex,
    Text,
    Heading,
    Stack,
    Input,
    InputLeftElement,
    InputGroup,
    Icon,
    VStack,
    Spinner,
} from "@chakra-ui/react";
import { useTable } from 'react-table'
import { AiOutlineSearch } from 'react-icons/ai'
import { useHistory } from 'react-router-dom';
import Drawer from '../layout/Drawer';
import { Context } from '../../context/Context';
import { useCurrencies } from './helpers/fetcher';
import ProfileHeader from '../layout/ProfileHeader'


function Currencies() {
    const history = useHistory()
    const { user } = useContext(Context)

    const { data: currencies, isLoading } = useCurrencies(`currencies`, user.token)


    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "currency",

            },
            {
                Header: "Code",
                accessor: "code"
            },
        ], []
    )
    const data = useMemo(() => currencies || [], [currencies])

    const tableInstance = useTable({ columns, data })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance


    return (
        <Flex>
            <Drawer />
            <Box px={5} py={7} bg="brand.background" w="80%" minHeight="100vh">
                <Flex justify="space-between">
                    <Heading as="h4" size="md">Currencies</Heading>
                    <ProfileHeader />
                </Flex>
                <Box bg="white" p={5} rounded="lg" mt={5}>
                    <Stack spacing={4}>
                        <Heading as="h5" size="sm">All Currencies</Heading>
                        <Flex justify="between">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<Icon as={AiOutlineSearch} color="gray.500" />}
                                />
                                <Input type="phone" placeholder="Search" w={60} />
                            </InputGroup>
                            <Stack spacing={4} direction="row" align="center">
                                <Button onClick={() => history.push('/currencies/add')} colorScheme="teal" size="md">
                                    <Text fontWeight="normal">Add New Currency</Text>
                                </Button>
                            </Stack>
                        </Flex>
                    </Stack>
                    <Box mt={4} overflow="auto">
                        <table {...getTableProps()}>
                            <thead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (

                                                    <th {...column.getHeaderProps()}>
                                                        {
                                                            column.render('Header')}
                                                    </th>
                                                ))}
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {// Loop over the table rows
                                    rows.map(row => {
                                        // Prepare the row for display
                                        prepareRow(row)
                                        return (
                                            // Apply the row props
                                            <tr {...row.getRowProps()}>
                                                {// Loop over the rows cells
                                                    row.cells.map(cell => {
                                                        // Apply the cell props
                                                        return (
                                                            <td {...cell.getCellProps()}>
                                                                {// Render the cell contents
                                                                    cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        {isLoading &&
                            <VStack mt={4}>
                                <Spinner color="brand.green" />
                            </VStack>}
                        {!isLoading && currencies?.length === 0 && <Text textAlign="center">No records found</Text>}
                    </Box>
                </Box>
            </Box>
        </Flex>
    )
}

export default Currencies;