import React from 'react';
import * as dayjs from 'dayjs';
import { renderGenericTrueFalse, renderGenericPassFail } from '../verification/helpers/renderData';


function ReportTable({ item }) {
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th className="report-th">Reference ID</th>
                        <th className="report-th">ID</th>
                        <th className="report-th">Organization</th>
                        <th className="report-th">Created</th>
                        <th className="report-th">Status</th>
                        <th className="report-th">Within State / Region</th>
                        <th className="report-th">Within LGA / District</th>
                        <th className="report-th">Within Country</th>
                        <th className="report-th">Overlay</th>
                        <th className="report-th">Overlay ID</th>
                        <th className="report-th">Reason</th>
                        <th className="report-th">Crop</th>
                        <th className="report-th">State / Region</th>
                        <th className="report-th">LGA / District</th>
                        <th className="report-th">Confirmed Size</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="report-th" >{item.reference}</td>
                        <td className="report-th">{item._id}</td>
                        <td className="report-th">{item.partner}</td>
                        <td className="report-th">{dayjs(item.created).format('YYYY/MM/DD')}</td>
                        <td className="report-th">{renderGenericPassFail(item.overall_status)}</td>
                        <td className="report-th">{renderGenericTrueFalse(item.within_state)}</td>
                        <td className="report-th">{renderGenericTrueFalse(item.within_lga)}</td>
                        <td className="report-th">
                            {renderGenericTrueFalse(item.within_country)}
                        </td>
                        <td className="report-th">{renderGenericPassFail(item.overlay)}</td>
                        <td className="report-th">{item.overlay_ids}</td>
                        <td className="report-th">{item.reject_reason}</td>
                        <td className="report-th">{item.crop}</td>
                        <td className="report-th">{item.state}</td>
                        <td className="report-th">{item.lga}</td>
                        <td className="report-th">{item.returned_size}</td>
                    </tr>
                </tbody>
            </table>
            <br />
        </>


    )
}

export default ReportTable




